import { Modal } from "antd"
import StepCard from "./StepCard"
import './styles.scss'
import { PrimaryTheme } from "../../../../../../../../../../../Config/Theme/theames"
import { BEButton } from "../../../../../../../../../../../Components/BEFormItems/BEButton"

interface UploadWarningModalProps{
	open: boolean
	onCancel: Function
	errorPercent:any
}

export function UploadWarningModal(props: UploadWarningModalProps){
	const cardsData=[
		{
			id:1,
			title:'Accept and continue',
			description: `Continue with the ${100 - props.errorPercent}% data that is correct and those values will be used for calculations. Please do download the rejected ${props.errorPercent}% entries for correcting and reuploading it later.`
		},
		{
			id:2,
			title:'Reupload',
			description: 'Consider downloading the evaluated file with remarks for rejected entries to correct them and upload as a new file to avoid duplication errors.',
			bestApproach: true
		},
		{
			id:3,
			title:'Exit',
			description: 'Exit the process by clicking cancel/close and the file will be marked as inactive and stored in the logs.'
		}
	]
	return (
		<Modal className="modal" width={"60%"} footer={null} open={props.open} onCancel={()=>props.onCancel(false)}>
			<h2>Warning: {props.errorPercent}% Errors Found</h2>
			<p>Kindly proceed with any one of the following options. We recommend the 2nd option.</p>
			<div className="card-group">
			{
				cardsData.map(card=>{
					return (<StepCard 
								cardNumber={card.id}
								title={card.title}
								description={card.description}
								bestApproach={card.bestApproach ? true : false}
							/>)
				})
			}
			</div>
			<div className="note" >
				<p >Please note: All uploaded files can be marked as Inactive which will result in its data not being used. An inactive file cannot be made active again.</p>
			</div>
			<div className="close-btn">
				<BEButton onClick={()=>props.onCancel(false)}>
					Close
				</BEButton>
			</div>
		</Modal>
	)
}