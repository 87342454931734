import { ColumnsType } from "antd/es/table"
import { BETable } from "../../../../../../../Components/BETable"
import { BEButton } from "../../../../../../../Components/BEFormItems/BEButton"
import BEStatusTag from "../../../../../../../Components/BEStatusTag"
import { PrimaryTheme } from "../../../../../../../Config/Theme/theames"
import React, { useState } from "react"
import AddBulkDataSteps from '../UploadManager/Drawers/AddBulkData'
import './styles.scss';
import CancleIcon from "../../../../../../../Components/BEIcons/CancleIcon"
import CheckIcon from "../../../../../../../Components/BEIcons/CheckIcon"
import ExportExcelIcon from "../../../../../../../Components/BEIcons/ExportIcons/ExportExcelIcon"
import EditIcon from "../../../../../../../Components/BEIcons/EditIcon"
import { RejectDataDrawer } from "./Drawers/RejectData"
import { useTypedSelector } from "../../../../../../../Config/Hooks/useTypedSelector"
import { setCurrentMyMetricDataBulkUploadFileId } from "../../../../../../../Redux/DataManagerReducer"
import { useAppDispatch } from "../../../../../../../Config/Hooks/useAppDispatch"
import CrossIcon from "../../../../../../../Components/BEIcons/CrossIcon"
import { Dropdown, MenuProps } from "antd"
import MoreVertIcon from "../../../../../../../Components/BEIcons/MoreVertIcon"
import { getMyMetricBulkUploadData, myMetricDataBulkUpload } from "../../../../../Actions"
import { GlobalLoader } from "../../../../../../../Components/GlobalLoader"
import exportToCSV from "../../../../../../../Utils/Download/Excell/ExportExcell"
import ViewDataModal from "./ViewDataModal"
import { ChangeDateFormat } from "../../../../../../../Config/Functions/UsefullFunctions"

export interface IUploadManagerProps {
	my_metric_id: number;
}

export function UploadManager(props: IUploadManagerProps) {
	const [openBulkUploadDrawer, setOpenBulkUploadDrawer] = useState<boolean>(false);
	const [showRejectedEntryDrawer, setShowRejectedEntryDrawer] = useState<boolean>(false);
	const [viewDataModal, setViewDataModal] = useState<boolean>(false)
	const users = useTypedSelector(state => state.userMgmt.data);
	const [viewOnly, setViewOnly] = useState<boolean>(false);
	const myMetricdataBulkUploadFiles = useTypedSelector(state => state.dataManager.myMetricDataBulkUploadFiles);
	const currentMyMetricDataBulkUploadFileId = useTypedSelector(state => state.dataManager.currentMyMetricDataBulkUploadFileId);
	const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadsOnExcel, setLoadsOnExcel] = useState<boolean>(false);
	const [currentFileId, setCurrentFileId] = useState<number | null>(null);
	const [bulkUploadFiles, setBulkUploadFiles] = useState<any[]>([]);
	const [acceptedData, setAcceptedData] = useState<any[]>();
	const role = localStorage.getItem('role');
	const dispatch = useAppDispatch();


	React.useEffect(() => {
		const userId = JSON.parse(localStorage.getItem('userInfo') || '{}').id;
		let bulkUploadFiles = myMetricdataBulkUploadFiles.data.filter((item) =>
			item.my_metric_id === props.my_metric_id
			&& (item.business_unit_id === currentBusinessUnit || currentBusinessUnit === 0)
		);
		if (role === 'CONTRIBUTOR') {
			bulkUploadFiles = bulkUploadFiles.filter((item) => item.uploaded_by_id === userId);
		}
		setBulkUploadFiles(bulkUploadFiles);
	}, [props.my_metric_id, myMetricdataBulkUploadFiles, currentBusinessUnit]);

	const itemsRejected: MenuProps['items'] = [
		{
			key: '1',
			label: <div onClick={() => {
				setViewOnly(true);
				setShowRejectedEntryDrawer(true);
			}} style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}> View reason  </div>
		}
	];

	const aggregateColumns: ColumnsType<any> = [
		{
			title: "File Name",
			dataIndex: "title",
			key: "title",
		},
		{
			title: 'Uploaded By',
			dataIndex: 'uploaded_by_id',
			key: 'uploaded_by_id',
			render: (text: any, record: any) => {
				const user = users.find((user) => user.id === text);
				return <span>{user?.first_name} {user?.last_name}</span>
			},
			hidden: role === 'CONTRIBUTOR'
		},
		{
			title: 'Uploaded Date',
			dataIndex: 'uploaded_at',
			key: 'uploaded_at',
			render: (text: any, record: any) => <span>{text?.split('T')[0]}</span>
		},
		{
			title: 'Error percentage',
			dataIndex: 'error_percent',
			key: 'error_percent',
			render: (text: any, record: any) => <span>{record.error_percent}{'%'}</span>
		},
		{
			title: 'Status',
			key: 'status',
			render: (text: any, record: any) => record.status === 1 ?
				<BEStatusTag status="success">
					<span>ACTIVE</span>
				</BEStatusTag>
				: record.status === 0 ?
					<BEStatusTag status="warning">
						<span>PENDING</span>
					</BEStatusTag>
					:
					<BEStatusTag status="error">
						<span>INACTIVE</span>
					</BEStatusTag>
		},
		// {
		// 	title: 'Error%',
		// 	key: 'errorPercentage',
		// 	render: (record) => <span style={{ color: PrimaryTheme.primaryGreen }}>{record.errorPercentage}</span>
		// },
		{
			title: 'Actions',
			render: (text: any, data: any) =>
				<div>
					{
						data?.status === 0 ?
							<>
								{
									role !== 'CONTRIBUTOR' &&
									<div
										style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', cursor: 'pointer' }}>
										<div style={{ height: '1rem' }}
											onMouseEnter={() => {
												setCurrentFileId(data.id);
											}}
											onClick={() => {
												setViewOnly(false);
												setShowRejectedEntryDrawer(true);
											}}
										>
											<CrossIcon inheritSize stroke={PrimaryTheme.primaryRedError} />
										</div>
										<div style={{ height: '1rem', padding: '0.2rem 0.5rem', cursor: 'pointer' }}
											onClick={async () => {
												setLoading(true);
												const res = await dispatch(myMetricDataBulkUpload(props.my_metric_id, 1, null, null, data.id));
												setLoading(false);
											}}
										>
											<CheckIcon inheritSize stroke={PrimaryTheme.primaryGreen} />
										</div>
										<div
											onClick={async () => {
												setLoadsOnExcel(true);
												dispatch(setCurrentMyMetricDataBulkUploadFileId(data.id))
												const dataMyMetric = await dispatch(getMyMetricBulkUploadData());
												setAcceptedData(dataMyMetric?.accepted_data);
												setViewDataModal(true);
												setLoadsOnExcel(false);
											}}
											style={{ height: '1rem', cursor: 'pointer' }}
										>
											{loadsOnExcel && currentMyMetricDataBulkUploadFileId === data.id ? <GlobalLoader height="0.5rem" /> :
											<ExportExcelIcon inheritSize fill={PrimaryTheme.primaryGreen} />}
										</div>
									</div>
								}
							</>
							:
							data?.status === 2
								?
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<BEStatusTag status='error'><p>REJECTED</p></BEStatusTag>
									<div style={{ cursor: 'pointer' }}>
										<Dropdown
											trigger={['click']}
											menu={{ items: itemsRejected }}
										>
											<div><MoreVertIcon /></div>
										</Dropdown>
									</div>
								</div>
								:
								<div
									onMouseEnter={() => {
										setCurrentFileId(data.id);
									}}
									onClick={async () => {
										dispatch(setCurrentMyMetricDataBulkUploadFileId(data.id))
										const dataMyMetric = await dispatch(getMyMetricBulkUploadData());
										let dataToDownload: any = []
										if (currentBusinessUnit) {
											dataToDownload = dataMyMetric.accepted_data.map((item: any) => {
												return {
													'value': item.value,
													'date_of_entry(DD-MM-YYYY)': item.date_of_entry.split('T')[0],
													'description': item.description,
													'cost': item.cost,
													'business_unit_name': item.business_unit_name,
												}
											})
										} else {
											dataToDownload = dataMyMetric.accepted_data.map((item: any) => {
												return {
													'value': item.value,
													'date_of_entry(DD-MM-YYYY)': item.date_of_entry.split('T')[0],
													'description': item.description,
													'cost': item.cost,
												}
											})
										}

										exportToCSV(dataToDownload, 'MetricAcceptedData')
									}}
									style={{ height: '1rem', width: '1rem', cursor: 'pointer' }}
								>
									<ExportExcelIcon inheritSize fill={PrimaryTheme.primaryGreen} />
								</div>
					}
				</div>
		}
	];

	return (
		loading ?
			<GlobalLoader height="20vh" />
			:
			<div style={{ padding: '1rem' }}>
				<div style={{ display: 'flex', justifyContent: 'end', margin: '1rem' }}>
					<BEButton
						size="large"
						className="primary"
						onClick={() => {
							dispatch(setCurrentMyMetricDataBulkUploadFileId(null));
							setOpenBulkUploadDrawer(true);
						}}>
						+ Bulk upload
					</BEButton>
				</div>
				<BETable
					columns={aggregateColumns}
					data={bulkUploadFiles}
					rowClassName={(record: any) => record.status === 0 ? 'inactive' : ''}
				/>
				<AddBulkDataSteps
					open={openBulkUploadDrawer}
					setOpen={setOpenBulkUploadDrawer}
					my_metric_id={props.my_metric_id}
				/>
				<RejectDataDrawer
					viewOnly={viewOnly}
					open={showRejectedEntryDrawer}
					setOpen={setShowRejectedEntryDrawer}
					fileId={currentFileId as number}
					my_metric_id={props.my_metric_id}
				/>
				<ViewDataModal
					open={viewDataModal}
					setOpen={setViewDataModal}
					acceptedData={acceptedData}
					fileId={currentFileId as number}
				/>
			</div>
	)
}