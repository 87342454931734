import React, { useEffect, useState } from 'react'
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { SetTableAndGraphDataDM } from '../../../DataManager/Components/SetTableAndGraphDataFunctionDM';
import { DashboardCard } from '../../../../Components/Cards/DashboardCard';
import DashboardLineChart from '../../Components/DashboardLineChart';
import { getMyDashboardMetrics, getMyDerivedDashboardMetrics, patchMyDashboardMetrics, patchMyDerivedDashboardMetrics, postMyDashboardMetrics } from '../../Actions';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { AddTrends } from '../Drawers/AddTrends';
import { selectedMetricType } from '../../../../Redux/Types/dataManagerTypes';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import EmptyDashboard from '../../../../assets/images/OnboardingImages/EmptyDashboard.png';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { dashboardMetricsCardList, dashboardMetricsGraphList } from '../../../../Config/Data/dashboardData';
import { EsgMetricsConfigurations } from '../../../Settings/Drawers/EsgMetricsConfigurations/EsgMetricsConfiguration';
import { CalculatedDashboardMetrics } from '../../Components/CalculatedDashboardMetrics';
import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { getCombinedSelectedMetrics, getMyMetrics } from '../../../../views/Actions/OnboardingStep4Actions';
import { getMyMetricsData } from '../../../DataManager/Actions';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import { AnalyticsGraphMetrics } from './AnalyticsGraphMetrics';
import { AnalyticsGraphBUs } from './AnalyticsGraphBusinessUnits';

const Dahboard = () => {
  const dispatch = useAppDispatch()
  const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
  const myMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.myMetricsPerYear);
  const myMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetrics);
  const myDashboardMetrics = useTypedSelector(state => state.dataManager.metrics.myDashboardMetrics);
  const myMetricsData = useTypedSelector(state => state.onBoarding.metrics.myMetricsData);
  const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
  const tableDataDM = useTypedSelector(state => state.dataManager.metrics.tableDataDM);
  const [cardMetricsData, setCardMetricsData] = useState<any[]>([]);
  const [companyDetails, setCompanyDetails] = useState<boolean>(false);
  const [openAddTrends, setOpenAddTrends] = useState<boolean>(false);
  const [graphMetricsData, setGraphMetricsData] = useState<any[]>([]);
  const [load, setLoad] = useState<boolean>(true);
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const role = localStorage.getItem('role');
  const permissions = useTypedSelector(state => state.user.permissions?.data);
  const myDerivedDashboardMetrics = useTypedSelector(state => state.dataManager.metrics.myDerivedDashboardMetrics);
  const tableDataDerivedDM = useTypedSelector(state => state.dataManager.metrics.tableDerivedMetricsDataDM);
  const myMetricTargets = useTypedSelector(state => state.targets.MyMetricTargets);
  const myDerivedMetricTargets = useTypedSelector(state => state.targets.MyDerivedMetricTargets);
  const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBusinessGroup = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
  const businessUnitsRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);

  const getCurrentValue = (id: number, isDerived: boolean) => {
    if (!tableDataDM[id]) return 0;
    if (isDerived) {
      return tableDataDerivedDM?.data[id]
        .reduce((total: number, currentItem: any) => {
          return total + currentItem.value;
        }, 0);
    }

    return tableDataDM[id]
      .reduce((total: number, currentItem: any) => {
        return total + currentItem.value;
      }, 0);
  };

  const getAllMetricData = (id: number) => {
    return metrics?.data?.find((item) => item.id === id)
  };

  const getTargetPercent = (metric_id: number, is_derived: boolean) => {
    let progress: number = -1;

    if (is_derived) {
      if (currentBusinessUnit) {
        progress = myDerivedMetricTargets?.data?.find((item) =>
          item.derived_metric === metric_id &&
          item.business_unit === currentBusinessUnit &&
          item?.end_year === currentYear &&
          item?.start_year === currentYear
        )?.progress as number;
      } else if (currentBusinessGroup) {
        const businessUnits = businessUnitsRefs.data.filter((item: any) => item.business_group === currentBusinessGroup).map((item) => item.business_unit);
        progress = myDerivedMetricTargets?.data?.find((item) =>
          item.derived_metric === metric_id &&
          businessUnits.includes(item.business_unit as number) &&
          item?.end_year === currentYear &&
          item?.start_year === currentYear
        )?.progress as number;
      } else {
        progress = myDerivedMetricTargets?.data?.find((item) =>
          item.derived_metric === metric_id &&
          item?.end_year === currentYear &&
          item?.start_year === currentYear
        )?.progress as number;
      }
    } else {
      if (currentBusinessUnit) {
        progress = myMetricTargets?.data?.find((item) =>
          item.metric === metric_id &&
          item.business_unit === currentBusinessUnit &&
          item?.end_year === currentYear &&
          item?.start_year === currentYear
        )?.progress as number;
      } else if (currentBusinessGroup) {
        const businessUnits = businessUnitsRefs.data.filter((item: any) => item.business_group === currentBusinessGroup).map((item) => item.business_unit);
        progress = myMetricTargets?.data?.find((item) =>
          item.metric === metric_id &&
          businessUnits.includes(item.business_unit as number) &&
          item?.end_year === currentYear &&
          item?.start_year === currentYear
        )?.progress as number;
      } else {
        progress = myMetricTargets?.data?.find((item) =>
          item.metric === metric_id &&
          item?.end_year === currentYear &&
          item?.start_year === currentYear
        )?.progress as number;
      }
    }
    return progress ? AddCommaToNumber(progress, false, true, true, 2, true) : -1;
  }

  const addDefaultDashboardMetrics = async () => {
    if (myMetrics?.status !== 'success' || myDashboardMetrics?.status !== 'success') return;
    const metrics = myDashboardMetrics?.data.map((item) => item.my_metric);
    const years = metrics.map((item) => myMetrics?.data?.find((met: selectedMetricType) => item === met.id)?.year);
    if (years.includes(currentYear)) return;
    const metricsToAdd = dashboardMetricsGraphList.map((item) => {
      return {
        year: currentYear,
        my_metric: myMetrics?.data?.find((met: selectedMetricType) => item === met.title && met.year === currentYear)?.id,
        graph: true,
        card: false
      }
    }).filter((item) => item.my_metric !== undefined);
    const metricsToAddCard = dashboardMetricsCardList.map((item) => {
      return {
        year: currentYear,
        my_metric: myMetrics?.data?.find((met: selectedMetricType) => item === met.title && met.year === currentYear)?.id,
        graph: false,
        card: true
      }
    }).filter((item) => item.my_metric !== undefined);
    await dispatch(postMyDashboardMetrics([...metricsToAdd, ...metricsToAddCard], true));
  }

  useEffect(() => {
    addDefaultDashboardMetrics();
  }, [currentYear, myMetrics]);

  // useEffect(() => {
  //   console.log(cardMetricsData);
  //   console.log(graphMetricsData);
  // }, [cardMetricsData, graphMetricsData]);

  useEffect(() => {
    if (!load)
      setLoad(
        myDashboardMetrics?.status === 'loading' ||
        myMetrics?.status === 'loading' ||
        metrics?.status === 'loading' ||
        myMetricsData?.status === 'loading' ||
        myDerivedDashboardMetrics?.status === 'loading'
      )
  }, [load, myDashboardMetrics, myMetricsPerYear, myMetrics, metrics, myMetricsData, combinedSelectedMetricsPerYear, myDerivedDashboardMetrics]);

  useEffect(() => {
    if (
      myMetricsPerYear?.status === "success" &&
      myDashboardMetrics?.status === "success" &&
      myMetrics?.status === "success" &&
      metrics?.status === "success" &&
      myMetricsData?.status === "success" &&
      myDerivedDashboardMetrics?.status === "success"
    ) {
      if (role === 'CONTRIBUTOR') {
        const metricCardData = dashboardMetricsCardList.map((item) => {
          const selectedMetric = myMetrics?.data?.find((met: selectedMetricType) => item === met.title)
          const allMetricId = selectedMetric?.metric
          const allMetricData = allMetricId && getAllMetricData(allMetricId)
          const newAllMetricData = {
            "totalValue": selectedMetric && getCurrentValue(selectedMetric?.id, false),
            "targetPercent": selectedMetric && getTargetPercent(selectedMetric?.metric, false),
            "type": "card",
            "title": allMetricData && allMetricData?.title,
            "unit": allMetricData && allMetricData?.unit
          }
          return selectedMetric ? newAllMetricData : null;
        })
          .filter((item) => item !== null);
        setCardMetricsData(metricCardData);

        const metricGraphData = dashboardMetricsGraphList.map((item) => {
          const selectedMetric = myMetrics?.data?.find((met: selectedMetricType) => item === met.title)
          const allMetricId = selectedMetric?.metric;
          const allMetricData = allMetricId && getAllMetricData(allMetricId);
          const newAllMetricData = {
            "id": selectedMetric?.id,
            "title": selectedMetric?.title,
            "unit": allMetricData && allMetricData?.unit,
            "category": allMetricData && allMetricData?.category,
            "type": "graph"
          }
          return selectedMetric ? newAllMetricData : null;
        }).filter((item) => item !== null);
        setGraphMetricsData(metricGraphData);

      } else {
        const metricData = myDashboardMetrics?.data.filter((item) => item.card).map((item) => {
          const selectedMetric = myMetricsPerYear?.data?.find((met: selectedMetricType) => item.my_metric === met.id)
          const allMetricId = selectedMetric?.metric
          const allMetricData = allMetricId && getAllMetricData(allMetricId)
          const newAllMetricData = {
            ...allMetricData,
            "totalValue": selectedMetric && getCurrentValue(selectedMetric?.id, false),
            "targetPercent": selectedMetric && getTargetPercent(selectedMetric?.metric, false),
            "dashboardMetricId": item.id,
            "type": "card",
            "selectedMetricId": selectedMetric?.id,
            "isDerived": false,
            "position": item.position_card
          }
          return selectedMetric ? newAllMetricData : null;
        }).filter((item) => item !== null);

        const derivedMetricCardData = myDerivedDashboardMetrics?.data.filter((item) => item.card).map((item) => {
          const derivedMetric = combinedSelectedMetricsPerYear?.data?.find((met) => item.my_derived_metric === met.id);
          const totalValue: number = derivedMetric && tableDataDerivedDM?.data[derivedMetric.id]
            ?.reduce((total: number, currentItem: any) => {
              return total + currentItem.value;
            }, 0);
          const newAllMetricData = {
            ...derivedMetric,
            "totalValue": totalValue ? totalValue : 0,
            "targetPercent": derivedMetric && getTargetPercent(derivedMetric.derived_metric, true),
            "dashboardMetricId": item.id,
            "type": "card",
            "selectedMetricId": derivedMetric?.id,
            "isDerived": true,
            "position": item.position_card
          }
          return derivedMetric ? newAllMetricData : null;
        }).filter((item) => item !== null);
        setCardMetricsData([...metricData, ...derivedMetricCardData].length > 1 ?
          [...metricData, ...derivedMetricCardData]
            .sort((a: any, b: any) => a.position - b.position) :
          [...metricData, ...derivedMetricCardData]
        );

        const metricGraphData = myDashboardMetrics?.data.filter((item) => item.graph).map((item) => {
          const selectedMetric = myMetricsPerYear?.data?.find((met: selectedMetricType) => item.my_metric === met.id)
          const allMetricId = selectedMetric?.metric
          const allMetricData = allMetricId && getAllMetricData(allMetricId)
          const newAllMetricData = {
            "id": selectedMetric?.id,
            "title": selectedMetric?.title,
            "unit": allMetricData && allMetricData?.unit,
            "category": allMetricData && allMetricData?.category,
            "dashboardMetricId": item.id,
            "type": "graph",
            "bcode": allMetricData && allMetricData?.bcode,
            "isDerived": false,
            "position": item.position_graph
          }
          return selectedMetric ? newAllMetricData : null;
        }).filter((item) => item !== null);

        const derivedMetricGraphData = myDerivedDashboardMetrics?.data.filter((item) => item.graph).map((item) => {
          const derivedMetric = combinedSelectedMetricsPerYear?.data?.find((met) => item.my_derived_metric === met.id);
          const newAllMetricData = {
            "id": item?.id,
            "title": derivedMetric?.title,
            "unit": derivedMetric && derivedMetric?.unit,
            "category": derivedMetric && derivedMetric?.category,
            "dashboardMetricId": item.id,
            "type": "graph",
            "bcode": item?.bcode,
            "isDerived": true,
            "position": item.position_graph
          }
          return derivedMetric ? newAllMetricData : null;
        }).filter((item) => item !== null);
        setGraphMetricsData([...metricGraphData, ...derivedMetricGraphData].length > 1 ?
          [...metricGraphData, ...derivedMetricGraphData]
            .sort((a: any, b: any) => a.position - b.position) :
          [...metricGraphData, ...derivedMetricGraphData]
        );
      }
      setLoad(false);
    }
  }, [myDashboardMetrics, myDerivedDashboardMetrics, currentBusinessUnit, currentBusinessGroup, combinedSelectedMetricsPerYear, tableDataDerivedDM, myMetricsPerYear, myMetricsData, currentYear, tableDataDM]);

  useEffect(() => {
    if (myMetrics?.status === 'idle')
      dispatch(getMyMetrics());
    if (myMetricsData?.status === 'idle')
      dispatch(getMyMetricsData());
    if (myDashboardMetrics?.status === 'idle')
      dispatch(getMyDashboardMetrics());
    if (myDerivedDashboardMetrics?.status === 'idle')
      dispatch(getMyDerivedDashboardMetrics());
    if (combinedSelectedMetricsPerYear?.status === 'idle')
      dispatch(getCombinedSelectedMetrics());
  }, []);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor)
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    // Ensure 'over' is not null and that we're not dropping the item onto itself
    if (over && active.id !== over.id) {
      const oldIndex = cardMetricsData.findIndex(item => item.id === active.id);
      const newIndex = cardMetricsData.findIndex(item => item.id === over.id);
      const newCardMetricsdata = arrayMove(cardMetricsData, oldIndex, newIndex);
      Promise.all(newCardMetricsdata.filter((item) => item.dashboardMetricId !== undefined).map((item, index) => {
        if (item.isDerived)
          return dispatch(patchMyDerivedDashboardMetrics(item.dashboardMetricId, { position_card: index }))
        else
          return dispatch(patchMyDashboardMetrics(item.dashboardMetricId, { position_card: index }))
      }));

      {/*setCardMetricsData(newCardMetricsdata);*/ }
    }
  };

  // Helper function to reorder array (you might have this utility function elsewhere in your codebase)
  const arrayMove = (array: any[], from: number, to: number) => {
    const newArray = [...array];
    const startIndex = to < 0 ? newArray.length + to : to;
    const item = newArray.splice(from, 1)[0];
    newArray.splice(startIndex, 0, item);
    return newArray;
  };


  function SortableItem(props: any) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <DashboardCard {...props} />
      </div>
    );
  }

  function GraphSortableItem({ id, data }: { id: string, data: any }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {data.isDerived ?
          <CalculatedDashboardMetrics bcode={data.bcode} /> :
          <DashboardLineChart metricName={data.title} dashboardMetricId={data.dashboardMetricId} metricId={data.id} />
        }
      </div>
    );
  }

  const handleGraphDragEnd = (event: any) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = graphMetricsData.findIndex(item => item.id === active.id);
      const newIndex = graphMetricsData.findIndex(item => item.id === over.id);
      const newGraphMetricsdata = arrayMove(graphMetricsData, oldIndex, newIndex);
      Promise.all(newGraphMetricsdata.filter((item) => item.dashboardMetricId !== undefined).map((item, index) => {
        if (item.isDerived)
          return dispatch(patchMyDerivedDashboardMetrics(item.dashboardMetricId, { position_graph: index }))
        else
          return dispatch(patchMyDashboardMetrics(item.dashboardMetricId, { position_graph: index }))
      }));
      {/*setGraphMetricsData(newGraphMetricsdata);*/ }
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", margin: "1rem 0" }}>
      <div>
        {role !== "CONTRIBUTOR" && <BEButton
          disabled={permissions?.find((Item) => Item.role === role && Item.section === 1)?.permission === 0}
          className='primary'
          size='large'
          style={{ float: "right" }}
          onClick={() => { setOpenAddTrends(true) }}
        >
          + Add Trends
        </BEButton>}
      </div>
      {load ? <GlobalLoader height='20vh' /> : <>
        {cardMetricsData.filter((item) => item.title !== "Empty").length !== 0 ?
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={cardMetricsData.map(item => item.id)} strategy={verticalListSortingStrategy}>
              <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "1rem" }}>
                {cardMetricsData.filter((item) => item.title !== "Empty").map((item, index) => (
                  <div key={item.id} style={{ width: "24%" }}>
                    <SortableItem
                      key={item.id}
                      id={item.id}
                      metricName={item.title}
                      metricValue={item.totalValue}
                      metricUnit={item.unit}
                      targetPercent={Number(item.targetPercent)}
                      isDerived={item.isDerived}
                    />
                  </div>
                ))}
              </div>

            </SortableContext>
          </DndContext> :
          (role !== 'CONTRIBUTOR' &&
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", color: PrimaryTheme.primaryGreen, gap: "1rem" }}>
              <img height="150px" width="150px" src={EmptyDashboard}></img>
              Click Add Trends Button to select a dashboard metric
            </div>)
        }

        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleGraphDragEnd}>
          <SortableContext items={graphMetricsData.map(item => item.id)} strategy={verticalListSortingStrategy}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
              {graphMetricsData.filter(item => item.title !== "Empty").map((item, index) => (
                <div key={item.id} style={{ width: "49%" }}>
                  <GraphSortableItem key={item.id} id={item.id} data={item} />
                </div>
              ))}
            </div>
          </SortableContext>
        </DndContext>
      </>
      }
      {/* </div> */}
      <AddTrends
        open={openAddTrends}
        setOpen={setOpenAddTrends}
        cardMetricsData={cardMetricsData}
        graphsMetricsData={graphMetricsData}
        load={false}
      />
      <SetTableAndGraphDataDM />
      <EsgMetricsConfigurations open={companyDetails} setOpen={setCompanyDetails} />
    </div>


  );
}

export default Dahboard