import Administration from "../../assets/images/DepartmentImages/Administration.png";
import Finance from "../../assets/images/DepartmentImages/Finance.png";
import HumanResources from "../../assets/images/DepartmentImages/HumanResources.png";
import InformationTechnology from "../../assets/images/DepartmentImages/InformationTechnology.png";
import Legal from "../../assets/images/DepartmentImages/Legal.png";
import Operations_Projects from "../../assets/images/DepartmentImages/Operation_Projects.png";
import Procurement from "../../assets/images/DepartmentImages/Procurement.png";
import ESG_EHS from "../../assets/images/DepartmentImages/ESG_EHS.png";
import Others from "../../assets/images/DepartmentImages/Other.png";

export const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const UserDepartments = [
    {name: 'Administration', image: Administration},
    {name: 'Finance', image: Finance},
    {name: 'Human Resources', image: HumanResources},
    {name: 'Information Technology', image: InformationTechnology},
    {name: 'Legal', image: Legal},
    {name: 'Operations / Projects', image: Operations_Projects},
    {name: 'Procurement', image: Procurement},
    {name: 'ESG / EHS', image: ESG_EHS},
    {name: 'Others', image: Others}
];;

export const Features = ['Dashboard', 'DataManager', 'EntityManager', 'Reporting', 'Emissions', 'Targets', 'Surveys', 'Settings', 'Configuration']
export const Roles= ['SUPER_ADMIN', 'ADMIN', 'DEPARTMENT_HEAD', 'CONTRIBUTOR', 'ESG_CONSULTANT', 'AUDITOR']