import React from "react";
import { BETabs } from "../../../../Components/BETabs";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { TargetsTable } from "../TargetsTable";
import { PrimaryTheme } from "../../../../Config/Theme/theames";
import { SetTableAndGraphDataDM } from "../../../DataManager/Components/SetTableAndGraphDataFunctionDM";


export interface ITopTabsTargetsProps {
    type: string; // single | multi
}


export function TopTabsTargets(props: ITopTabsTargetsProps) {
    const [activeTab, setActiveTab] = React.useState("company");

    return (
        <div style={{ backgroundColor: PrimaryTheme.primaryGreyLight }}>
            <BETabs
                destroyInactiveTabPane
                className='target-tabs'
                size='large'
                type='card'
                activeKey={activeTab}
                tabBarGutter={20}
                tabBarStyle={{ marginLeft: 40, marginTop: '2rem' }}
                onChange={(key) => {
                    setActiveTab(key);
                }}

                items={
                    [
                        {
                            key: "company",
                            label: "Company level",
                            children:
                            <TargetsTable type={props.type} level={"company"}  />
                        },
                        {
                            key: "business-unit",
                            label: "Business unit level",
                            children: <TargetsTable type={props.type} level={"business-unit"}  />
                        }
                    ]
                }
            />
            <SetTableAndGraphDataDM />
        </div>
    );
}
