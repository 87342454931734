import TextArea from "antd/es/input/TextArea"
import { BEDrawer } from "../../../../../../../../../Components/BEDrawer"
import { LableRequired } from "../../../../../../../../../Components/BEFormItems/LableRequired"
import React from "react"
import { PrimaryTheme } from "../../../../../../../../../Config/Theme/theames"
import { BEButton } from "../../../../../../../../../Components/BEFormItems/BEButton"
import { Form } from "antd"
import { BETextArea } from "../../../../../../../../../Components/BEFormItems/BETextArea"
import { useTypedSelector } from "../../../../../../../../../Config/Hooks/useTypedSelector"
import { myMetricDataBulkUpload, patchMyMetricDataBulkUploadFiles } from "../../../../../../../Actions"
import { useAppDispatch } from "../../../../../../../../../Config/Hooks/useAppDispatch"

interface RejectDataDrawerProps {
	open: boolean;
	setOpen: Function;
	viewOnly?: boolean;
	fileId: number;
	my_metric_id: number;
}

export function RejectDataDrawer(props: RejectDataDrawerProps) {
	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState(false);
	const dispatch = useAppDispatch();
	const myMetricdataBulkUploadFiles = useTypedSelector(state => state.dataManager.myMetricDataBulkUploadFiles);

	const onFinish = async () => {
		setLoading(true);
		const description = form.getFieldValue('description');
		await dispatch(myMetricDataBulkUpload(props.my_metric_id, 2, null, null, props.fileId, description));
		setLoading(false);
		props.setOpen(false);
	}

	React.useEffect(() => {
		const rejReason = myMetricdataBulkUploadFiles.data.find((item) => item.id === props.fileId)?.rejection_reason;
		if (props.viewOnly)
			form.setFieldValue('description', rejReason);
	}, [props.open, props.my_metric_id, myMetricdataBulkUploadFiles, props.viewOnly]);

	return (
		<BEDrawer
			footer={
				<>
					{
						!props.viewOnly &&
						<BEButton
							loading={loading}
							size="large"
							className="primary"
							onClick={() => form.submit()}
						>
							Reject and Close
						</BEButton>
					}
					<BEButton
						size="large"
						onClick={() => props.setOpen(false)}
					>
						Cancel
					</BEButton>
				</>
			}
			heading="Rejection summary"
			open={props.open}
			setOpen={props.setOpen}
		>
			<Form
				form={form}
				layout='vertical'
				onFinish={onFinish}
			>
				<LableRequired>
					Details
				</LableRequired>
				{
					props.viewOnly ?
						<p style={{ color: PrimaryTheme.secondaryRedError, fontSize: '0.8rem' }}>
							{myMetricdataBulkUploadFiles.data.find((item) => item.my_metric_id === props.my_metric_id)?.rejection_reason}
						</p>
						:
						<BETextArea
							value={form.getFieldValue('description')}
							placeholder={'Type here'}
							form={form}
							name={'description'}
							showCount
							maxLength={1000}
							rows={6}
							onChange={(e: any) => {
								form.setFieldValue('description', e.target.value);
							}}
							disabled={props.viewOnly}
						/>
				}
			</Form>
		</BEDrawer>
	)
}