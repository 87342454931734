import * as React from 'react';
import { TopTabsTargets } from '../../Components/TopTabs';

export interface ISingleYearTargetsProps {
}

export function SingleYearTargets (props: ISingleYearTargetsProps) {
  return (
    <div>
        <TopTabsTargets type='single' />
    </div>
  );
}
