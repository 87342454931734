import { REQUEST_OTP, VERIFY_OTP,GET_NEW_TOKEN, LOGOUT,POST_ACCESS_REQUEST } from "../../Utils/Routes/UserRouts";
import { signIn, setOtp, signOut, signInFailed, setResendOtp } from "../../Redux/UserReducer";
import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../Navigation/NavigationManager";
import { AppDispatch } from "../../Redux/store";
import { BEMessage } from "../../Components/BEMessage";
import { BENotify } from "../../Components/BENotify";
import { clearStore } from "../../Redux/ExtraReducrActions";

export const getOtp = (email: string) => async (dispatch: AppDispatch) => {
    dispatch(setOtp('loading'));
    const [data, error] = await handleAPICall(REQUEST_OTP({ email: email }));
    if (data) {
        dispatch(setOtp('success'));
        BEMessage.success('OTP sent successfully')
    }
    else {
        dispatch(setOtp('error'))
        // BEMessage.error('This Email ID isn’t registered')
        BENotify.info(
            "Hey, this email is not registered with a client account",
            "If you would like to onboard with us, please connect with us at info@breatheesg.com.",
            () => { window.location.href = "mailto:info@breatheesg.com"; }
        );
    }
}

export const verifyOtp = (email: string, otp: string, redirect?: string) => async (dispatch: AppDispatch) => {
    dispatch(setOtp('loading'));
    const [data, error] = await handleAPICall(VERIFY_OTP({ email: email, otp: otp }));
    dispatch(setOtp('success'))
    if (data) {
        dispatch(signIn(data));
        if (redirect) navigateTo(redirect);
        else if (data?.userInfo?.role === 'ESG_CONSULTANT')
            navigateTo('/choose-company');
        else
            navigateTo('/home/dashboard');
    }
    else {
        BEMessage.error('Invalid OTP')
        dispatch(setOtp('error'))
        dispatch(signInFailed());
    }
}

export const getNewToken = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_NEW_TOKEN());
    if (data) {
        return data?.data;
    }
    else {
        BEMessage.error('Something went wrong, please try again later.')
        return null;
    }
}

export const resendOtp = (email: string) => async (dispatch: AppDispatch) => {
    dispatch(setResendOtp('loading'));
    const [data, error] = await handleAPICall(REQUEST_OTP({ email: email }));
    if (data) {
        dispatch(setResendOtp('success'));
        BEMessage.success('OTP sent successfully')
    }
    else {
        dispatch(setResendOtp('error'))
    }
}

export const logoutAction = () => async (dispatch: AppDispatch) => {
    let body = {
        refresh_token: localStorage.getItem('refresh')
    }
    const [data, error] = await handleAPICall(LOGOUT(body));
    if (data) {
        dispatch(signOut());
        navigateTo('/login');
        dispatch(clearStore());
        localStorage.clear();
    }
    else {
        BEMessage.error('Something went wrong, please try again later.')
    }
}

export const onGoogleLogin = async () => {
    const googleClientId = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID as string;
    const apiBaseURL = process.env.REACT_APP_API_URL;
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = `${apiBaseURL}/auth/login/google/`
    const scope = [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
    ].join(' ');

    const params = {
        response_type: 'code',
        client_id: googleClientId,
        redirect_uri: redirectUri,
        prompt: 'consent',
        access_type: 'offline',
        scope
    };

    const urlParams = new URLSearchParams(params).toString();
    window.location.href = `${googleAuthUrl}?${urlParams}`;
};

export const onGoogleLoginSuccess = (location: any) => async (dispatch: AppDispatch) => {
    const params = new URLSearchParams(location.search);
    const userInfo = params.get('user_info');
    const token = params.get('token');
    if (userInfo && token) {
        const userData = JSON.parse(userInfo || "{}");
        const tokenData = JSON.parse(token || "{}");
        const data = {
            token: tokenData,
            userInfo: userData
        }
        dispatch(signIn(data));
        if (userData?.role === 'ESG_CONSULTANT')
            navigateTo('/choose-company');
        else
            navigateTo('/home/dashboard');
    }
}

export const onGoogleLoginFailed = (location: any) => {
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    let msg = '';
    if (error === 'unauthorized') {
        msg = "Hey, this email is not registered with a client account. If you would like to onboard with us, please connect with us at info@breatheesg.com.";
    }
    return msg;
}


export const onMicrosoftLogin = async () => {


const tenantId = '8f194b17-bcde-4a47-aee1-75f1bd9a098e';

const clientId = '0ff462d1-7220-45be-83a4-71f026a939e5'
// const redirectUri = 'http://localhost:8000/api/v1/auth/login/microsoft/';
const redirectUri = 'https://testapiv1.breatheesg.com/api/v1/auth/login/microsoft/';

const scope = 'User.ReadBasic.All User.Read';
const authUrl = `https://login.microsoftonline.com/8f194b17-bcde-4a47-aee1-75f1bd9a098e/oauth2/v2.0/authorize`;

const params = {
    response_type: 'code',
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: scope,
    state: 'State'
};


    const urlParams = new URLSearchParams(params).toString();
    window.location.href = `${authUrl}?${urlParams}`;
};

export const onMicrosoftLoginSuccess = (location: any) => async (dispatch: AppDispatch) => {
    const params = new URLSearchParams(location.search);
    const userInfo = params.get('user_info');
    const token = params.get('token');
    if (userInfo && token) {
        const userData = JSON.parse(userInfo || "{}");
        const tokenData = JSON.parse(token || "{}");
        const data = {
            token: tokenData,
            userInfo: userData
        }
        dispatch(signIn(data));
        if (userData?.role === 'ESG_CONSULTANT')
            navigateTo('/choose-company');
        else
            navigateTo('/home/dashboard');
    }
}

export const onMicrosoftLoginFailed = (location: any) => {
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    let msg = '';
    if (error === 'unauthorized') {
        msg = "Hey, this email is not registered with a client account. If you would like to onboard with us, please connect with us at info@breatheesg.com.";
    }
    return msg;
}


export const accessRequestForm=(body:any)=>async(dispatch:AppDispatch)=>{
	const [data,error]=await handleAPICall(POST_ACCESS_REQUEST(body))
    if(data){
        BEMessage.success('Data Sent Successfully');
        return data
    }else{
        BENotify.info("Some Error Happend","Try Again",() => { window.location.href = "mailto:info@breatheesg.com"; })
    }
}