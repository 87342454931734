import { PrimaryTheme } from "../../../../../../../../../../../../Config/Theme/theames";

interface StepCardProps{
	cardNumber: number;
	title: string;
	description: string;
	bestApproach:boolean
}
export default function StepCard(props: StepCardProps){
	return <div className="card" >
		<div className="card-number">{props.cardNumber}</div>
		<div className="card-title" >
        	<h2>{props.title} </h2>
			{props.bestApproach && <span className="best-approach">Best approach</span>}
		</div>
        <p>{props.description}</p>
	</div>
}