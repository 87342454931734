import * as React from 'react';
import { metricType, myMetricType } from '../../../../../../Redux/Types/dataManagerTypes';
import { BECollapse } from '../../../../../../Components/BECollapse';
import { BETabs } from '../../../../../../Components/BETabs';
import { MEtricDataTable } from './MetricDataTable';
import BEStatusTag from '../../../../../../Components/BEStatusTag';
import { TrendGraph } from './TrendGraph';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import ToolTip from '../../../../../../Components/BEToolTip';
import { DataManagerLogs } from './Logs';
import { deleteOmittedMetrics, getTopicStatusDM, getTotalMetricValueDM, postOmittedMetrics } from '../../../../Actions';
import { topicStatusMapDM } from '../../TopTabsDM/StatusFilter';
import { AddCommaToNumber } from '../../../../../../Config/Functions/UsefullFunctions';
import { BEEyeButton } from '../../../../../../Components/BEEyeButton';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { UploadManager } from './UploadManager';
import BECollab from '../../../../../../Components/BECollab';

export interface IKPIDataProps {
    kpi: myMetricType
}

export function KPIData(props: IKPIDataProps) {
    const dispatch = useAppDispatch();
    const role = localStorage.getItem('role');
    const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
    const [status, setStatus] = React.useState<any>(0); // 0: incomplete, 1: pending, 2: approved
    const tableDataDM = useTypedSelector(state => state.dataManager.metrics.tableDataDM);
    const [totalValue, setTotalValue] = React.useState<number>(0);
    const [isOmitted, setIsOmitted] = React.useState(false);
    const omittedMetrics = useTypedSelector(state => state.dataManager.metrics.omittedMetrics);
    const contributorsByMetrics = useTypedSelector(state => state.dataManager.contributorsByMetrics);
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const users = useTypedSelector(state => state.userMgmt);

    const setTotalValueFunc = async () => {
        let tempTotalValue: number = await dispatch(getTotalMetricValueDM(props.kpi))
        setTotalValue(tempTotalValue);
    }

    React.useEffect(() => {
        setTotalValueFunc();
    }, [tableDataDM, props.kpi])

    React.useEffect(() => {
        setIsOmitted(omittedMetrics?.data.some((item) => item.my_metric_id === props.kpi.metric && item.year === currentYear && item.business_unit === currentBusinessUnit))
    }, [omittedMetrics, props.kpi, currentBusinessUnit, currentYear])

    const getTopicStatus = async () => {
        let res = await dispatch(getTopicStatusDM(props.kpi.id))
        setStatus(res)
    }

    React.useEffect(() => {
        // if (tableDataDM[props.kpi.id]?.length > 0) {
        //     let tempStatus: any = 0;
        //     for (let i = 0; i < tableDataDM[props.kpi.id].length; i++) {
        //         if (tableDataDM[props.kpi.id][i]?.status === 2) {
        //             if (tempStatus === 0) {
        //                 tempStatus = 2;
        //             }
        //         }
        //         if (tableDataDM[props.kpi.id][i]?.status === 1) {
        //             tempStatus = 1;
        //         }
        //     }
        //     setStatus(tempStatus);
        // }
        // else setStatus(0)
        getTopicStatus();
    }, [tableDataDM, props.kpi])

    return (
        <div className='kpi-data'>
            <BECollapse
                closeCollapse={isOmitted}
                headingRightContent={
                    <div className='right-topic-heading'>

                        {isOmitted && currentBusinessUnit !== 0 ? <BEStatusTag status='info'>Omitted</BEStatusTag>
                            :
                            <>
                                <BECollab
                                    users={
                                        users.data.filter((item) => item.id ===
                                        contributorsByMetrics.data.find((item) => item.my_metric === props.kpi.id && item.business_unit === currentBusinessUnit)?.user)
                                        .map((item) => {
                                            return {
                                                id: item.user,
                                                name: item.first_name + ' ' + item.last_name,
                                                avatarUrl: item.profile_pic
                                            }
                                        })
                                    }
                                />
                                <BEStatusTag
                                    status={topicStatusMapDM?.find((item) => item.value === status)?.type || 'error'}
                                >
                                    <div
                                        className='status-tag-metrics'>
                                        {
                                            topicStatusMapDM?.find((item) => item.value === status)?.name || 'Pending'
                                        }
                                    </div>
                                </BEStatusTag>
                                <BEStatusTag status="info">
                                    {AddCommaToNumber(totalValue, false, true, true) + '  ' + props.kpi.unit}
                                </BEStatusTag>
                            </>}
                    </div>
                }
                headingStyle={{ height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                heading={
                    <div className='kpi-heading'>
                        <p>
                            {props.kpi?.title}
                        </p>
                        {props.kpi.description !== '' && props.kpi.description !== "nan" &&
                            <BEEyeButton title={undefined} discription={props.kpi.description}>
                            </BEEyeButton>
                        }
                        {
                            props.kpi?.annual === true && (props.kpi?.applicable_to_all === true ?
                                <ToolTip title='These values are entered once a year.'>
                                    <BEStatusTag status='success'>A</BEStatusTag>
                                </ToolTip> :
                                <ToolTip title='These levels are Annual Metrics for the company and are entered once a year.'>
                                    <BEStatusTag status='success'>HQ-A</BEStatusTag>
                                </ToolTip>)

                        }
                    </div>
                }
            >
                <BETabs size='small'
                    tabBarStyle={{ marginLeft: 20, marginTop: '1rem' }}
                    items={
                        props.kpi?.annual === true ? [
                            {
                                key: '1',
                                label: 'Data',
                                children: <MEtricDataTable
                                    metric={props.kpi} />
                            },
                            {
                                key: '2',
                                label: 'Logs',
                                children: <DataManagerLogs metric={props.kpi} />
                            }
                        ] :


                            [
                                {
                                    key: '1',
                                    label: 'Data',
                                    children: <MEtricDataTable
                                        metric={props.kpi} />
                                },
                                {
                                    key: '2',
                                    label: 'Trend',
                                    children: <TrendGraph
                                        metric={props.kpi}
                                        isFullYear
                                        metricID={props.kpi.id}
                                    />
                                },
                                {
                                    key: '3',
                                    label: 'Upload Manager',
                                    children: <UploadManager my_metric_id={props.kpi.id} />
                                },
                                {
                                    key: '4',
                                    label: 'Logs',
                                    children: <DataManagerLogs metric={props.kpi} />
                                }
                            ].slice(0, role === 'CONTRIBUTOR' ? 3 : 4)
                    }
                />
                {/* {
                            currentBusinessUnit !== 0 && !props.kpi.annual &&
                            <BEButton
                                loading={omitLoad}
                                style={{ float: 'right', marginBottom: '1rem', marginRight: '1rem' }}
                                size='large'
                                onClick={async () => {
                                    setOmitLoad(true);
                                    const data = {
                                        my_metric_id: props.kpi.metricId,
                                        year: currentYear,
                                        business_unit: currentBusinessUnit
                                    }
                                    const omittedMetric = omittedMetrics?.data?.find((item) => item.my_metric_id === props.kpi.metricId && item.year === currentYear && item.business_unit === currentBusinessUnit)
                                    if (isOmitted) {
                                        await dispatch(deleteOmittedMetrics(omittedMetric?.id!));
                                    } else {
                                        await dispatch(postOmittedMetrics([data]));
                                    }
                                    setOmitLoad(false);
                                }}
                            >
                                {isOmitted ? 'Activate' : 'Omit'}
                            </BEButton>
                        } */}
            </BECollapse>
        </div>
    );
}
