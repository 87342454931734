import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { ColumnsType } from 'antd/es/table';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { BETable } from '../../../../../Components/BETable';
import DeleteIcon from '../../../../../Components/BEIcons/DeleteIcon';
import { createMyDerivedMetricTarget, createMyMetricTarget, getTargetValue, updateMyDerivedMetricTarget, updateMyMetricTarget } from '../../../Actions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { BEMessage } from '../../../../../Components/BEMessage';
import dayjs from 'dayjs';
import BEConfirmModal from '../../../../../Components/BEConfirmModal';

export interface ISetupTargetProps {
    open: boolean;
    setOpen: Function;
    selectedMetric: any;
    type: string;
    level: string;
    isEdit?: boolean;
}

export function SetupTarget(props: ISetupTargetProps) {
    const [loading, setLoading] = React.useState(false);
    const [targetType, setTargetType] = React.useState('A');
    const existingYears = useTypedSelector(state => state.common.financialYears?.data);
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const myMetricTargets = useTypedSelector(state => state.targets.MyMetricTargets);
    const MyDerivedMetricTargets = useTypedSelector(state => state.targets.MyDerivedMetricTargets);
    const [openModal, setOpenModal] = React.useState(false);
    const [targetvalueForPercentType, setTargetValueForPercentType] = React.useState<number>(0);
    const [triggerTargetcalulation, setTriggerTargetcalulation] = React.useState<boolean>(false);
    const [startYear, setStartYear] = React.useState<number>(currentYear);
    const [endYear, setEndYear] = React.useState<number>(currentYear);
    const [yearValues, setYearValues] = React.useState<any>({});
    const [tableData, setTableData] = React.useState<any>([]);
    const [yearOfSpecificTargetValue, setYearOfSpecificTargetValue] = React.useState<any>(null);
    const [yearSpecificTargetValue, setYearSpecificTargetValue] = React.useState<any>(null);
    const yearEndDate = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);
    const [isReportingYearEndDecember, setIsReportingYearEndDecember] = React.useState(false);
    const [businessUnit, setBusinessUnit] = React.useState<any>(null);
    const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const options = [
        { value: 'A', label: 'absolute increase' },
        { value: 'B', label: 'absolute decrease' },
        { value: 'C', label: '% increase' },
        { value: 'D', label: '% decrease' }
    ];

    const columns: ColumnsType<any> = [
        {
            title: 'Year',
            dataIndex: 'year'
        },
        {
            title: 'Value',
            dataIndex: 'value'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) =>
                <div
                    style={{ width: "1rem", cursor: record.isExisting ? "not-allowed" : "pointer" }}
                    onClick={() => {
                        if (record.isExisting) return;
                        const data = tableData.filter((item: any) => item.year !== record.year);
                        setTableData(data);
                    }}
                >
                    <DeleteIcon
                        inheritSize
                        stroke={PrimaryTheme.primaryGray}
                    />
                </div>
        }
    ];

    const handleChange = (value: any) => {
        !value ? setTargetType('A') : setTargetType(value);
    }

    const checkAllBUValueExceedsCompanyValue = () => {
        const isTargetBeingSetAlongCompany = businessUnit === null;
        const targetValue = form.getFieldValue('targetValue');
        const baselineValue = form.getFieldValue('baselineValue') ? form.getFieldValue('baselineValue') : 0;
        let targetAlongCompany: any = myMetricTargets?.data.find((item) => item.metric === props.selectedMetric?.metric
            && item.business_unit === null
            && item.start_year === startYear
            && item.end_year === endYear
        );
        if (props.selectedMetric?.type === 'derived') {
            targetAlongCompany = MyDerivedMetricTargets?.data.find((item) => item.derived_metric === props.selectedMetric?.derived_metric
                && item.business_unit === null
                && item.start_year === startYear
                && item.end_year === endYear
            );
        }
        let targetAlongBUs: any = myMetricTargets?.data.filter((item) => item.metric === props.selectedMetric?.metric
            && item.business_unit !== null
            && item.start_year === startYear
            && item.end_year === endYear
        );
        if (props.selectedMetric?.type === 'derived') {
            targetAlongBUs = MyDerivedMetricTargets?.data.filter((item) => item.derived_metric === props.selectedMetric?.derived_metric
                && item.business_unit !== null
                && item.start_year === startYear
                && item.end_year === endYear
            );
        }
        let buTargetValue = targetAlongBUs.reduce((acc: any, val: any) => {
            return acc + val?.target_value
        }, 0);

        let companyTargetValue = targetAlongCompany?.target_value;

        if (isTargetBeingSetAlongCompany) {
            companyTargetValue = getTargetValue(targetType, Number(targetValue), Number(baselineValue));
        } else {
            buTargetValue = getTargetValue(targetType, Number(targetValue), Number(baselineValue)) + buTargetValue;
        }

        console.log('companyTargetValue', companyTargetValue);
        console.log('buTargetValue', buTargetValue);

        if (companyTargetValue < buTargetValue) {
            return false;
        }
        return true;
    }

    React.useEffect(() => {
        // Extract reporting year end month and day, if 31st December, then set the year as it is, else set the year as year - 1
        const yearEndMonth = dayjs(yearEndDate).format('MM-DD').split('-')[0];
        const yearEndDay = dayjs(yearEndDate).format('MM-DD').split('-')[1];
        const yearEndMonthDay = `${yearEndMonth}-${yearEndDay}`;
        const yearEndMonthDay31Dec = '12-31';
        if (yearEndMonthDay === yearEndMonthDay31Dec) {
            setIsReportingYearEndDecember(true);
        }
    }, [yearEndDate]);

    const addYearSpecificTargetValueToTable = () => {
        // add year specific target value to table
        const year = yearOfSpecificTargetValue;
        const value = yearSpecificTargetValue;
        const isExisting = false;
        if (!year || !value) return;
        const totalValue = tableData.reduce((acc: any, val: any) => {
            return acc + val.value;
        }, 0);

        if (totalValue + value > getTargetValue(targetType, Number(form.getFieldValue('targetValue')), Number(form.getFieldValue('baselineValue')))) {
            BEMessage.error('Total target value should be less than or equal to the main target value');
            return;
        }

        const data = [...tableData, {
            isExisting,
            year,
            value
        }];
        setYearOfSpecificTargetValue(null);
        setYearSpecificTargetValue(null);
        setTableData(data);
    }

    const handleFormSubmit = async (values: any) => {
        setLoading(true);
        let data: any = {
            target_type: targetType,
            baseline: values.baselineValue ? Number(values.baselineValue) : 0,
            target_value: Number(values.targetValue),
            progress_threshold: getTargetValue(targetType, Number(values.targetValue), values.baselineValue ? values.baselineValue : 0) * Number(values.progressThreshold) / 100,
            business_unit: businessUnit ? businessUnit : null,
            start_year: startYear,
            end_year: endYear,
        }

        if (props.selectedMetric?.type === 'derived') {
            data = {
                ...data,
                derived_metric: props.selectedMetric?.derived_metric
            }
        } else {
            data = {
                ...data,
                metric: props.selectedMetric?.metric

            }
        }

        if (props.type === 'multi') {
            const singleYearTargets = tableData
                .filter((item: any) => !item.isExisting)
                .map((item: any) => {
                    let obj: any = {
                        target_type: targetType,
                        baseline: values.baselineValue ? Number(values.baselineValue) : 0,
                        target_value: Number(item.value),
                        progress_threshold: getTargetValue(targetType, Number(item.value), values.baselineValue ? values.baselineValue : 0) * Number(values.progressThreshold) / 100,
                        business_unit: businessUnit ? businessUnit : null,
                        start_year: item.year,
                        end_year: item.year
                    }
                    if (props.selectedMetric?.type === 'derived') {
                        obj = {
                            ...obj,
                            derived_metric: props.selectedMetric?.derived_metric
                        }
                    } else {
                        obj = {
                            ...obj,
                            metric: props.selectedMetric?.metric
                        }
                    }
                    return obj;
                });

            const totalValue = tableData.reduce((acc: any, val: any) => {
                return acc + getTargetValue(targetType, Number(val.value), values.baselineValue ? values.baselineValue : 0);
            }, 0);

            if (props.selectedMetric?.type === 'derived') {
                await Promise.all(singleYearTargets.map(async (item: any) =>
                    await dispatch(createMyDerivedMetricTarget([item]))));
            } else {
                await Promise.all(singleYearTargets.map(async (item: any) =>
                    await dispatch(createMyMetricTarget([item]))));
            }
        }

        if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
            if (props.isEdit) {
                data = {
                    ...data,
                    derived_metric: props.selectedMetric?.id
                }
                await dispatch(updateMyDerivedMetricTarget(props.selectedMetric?.targetId, data));
            } else {
                await dispatch(createMyDerivedMetricTarget([data]));
            }
        } else {
            if (props.isEdit) {
                data = {
                    ...data,
                    metric: props.selectedMetric?.id
                }
                await dispatch(updateMyMetricTarget(props.selectedMetric?.targetId, data));
            } else {
                await dispatch(createMyMetricTarget([data]));
            }
        }
        setLoading(false);
        props.setOpen(false);
    }

    const setEditTargetValues = () => {
        // set the values of the target to the form
        setTargetType(props?.selectedMetric?.target_type);
        setStartYear(props?.selectedMetric?.start_year);
        setEndYear(props?.selectedMetric?.end_year);
        setBusinessUnit(props?.selectedMetric?.business_unit);
    }

    React.useEffect(() => {
        if (props.selectedMetric && props.open && props.isEdit) {
            setEditTargetValues();
        }
    }, [props.selectedMetric, props.open]);


    React.useEffect(() => {
        if (props.isEdit) {
            const tValue = (props.selectedMetric?.target_type === 'C'
                ? ((props?.selectedMetric?.target_value - props?.selectedMetric?.baseline) / props?.selectedMetric?.baseline) * 100
                : props.selectedMetric?.target_type === 'D' ?
                    ((props?.selectedMetric?.baseline - props?.selectedMetric?.target_value) / props?.selectedMetric?.baseline) * 100
                    : props?.selectedMetric?.target_value);

            const progress = (props?.selectedMetric?.progress_threshold) / (props?.selectedMetric?.target_value) * 100;
            form.setFieldsValue({
                baselineValue: props?.selectedMetric?.baseline,
                targetValue: tValue,
                progressThreshold: progress,
                startYear: props?.selectedMetric?.start_year,
                endYear: props?.selectedMetric?.end_year,
                targetType: props?.selectedMetric?.target_type,
                businessUnit: props?.selectedMetric?.business_unit
            });
            setTargetValueForPercentType(props?.selectedMetric?.target_value);
        }
        let existingTargets: any = myMetricTargets?.data
            .filter((item) => item.metric === props.selectedMetric?.metric
                && item.start_year >= startYear
                && item.end_year <= endYear
                && item.business_unit === businessUnit
                && item.start_year === item.end_year
            );

        if (props.selectedMetric?.type === 'derived') {
            existingTargets = MyDerivedMetricTargets?.data
                .filter((item) => item.derived_metric === props.selectedMetric?.derived_metric
                    && item.start_year >= startYear
                    && item.end_year <= endYear
                    && item.business_unit === businessUnit
                    && item.start_year === item.end_year
                );
        }

        const existingTargetsData = existingTargets.map((item: any) => {
            return {
                isExisting: true,
                year: item.start_year,
                value: item.target_value
            }
        });
        const existingTargetDataYears = existingTargetsData.map((item: any) => item.year);
        const newTableData = tableData.filter((item: any) => item.isExisting && existingTargetDataYears.includes(item.year));
        const updatedTableData = [...newTableData.filter((item: any) => !existingTargetDataYears.includes(item.year)), ...existingTargetsData];
        setTableData(updatedTableData);
    }, [myMetricTargets, MyDerivedMetricTargets, props.selectedMetric, startYear, endYear, businessUnit, targetvalueForPercentType]);

    React.useEffect(() => {
        // make an object of year specific target values as {year: value, year: value, year: value}
        const tableDataYears = tableData.map((item: any) => item.year);
        const yearValues = existingYears
            .filter((year) => year >= startYear && year <= endYear && !tableDataYears.includes(year))
            .reduce((acc: any, year) => {
                acc[year] = year
                return acc
            }, {})
        setYearValues(yearValues);
    }, [tableData, startYear, endYear, existingYears]);

    React.useEffect(() => {
        if (!props.isEdit) {
        const value = getTargetValue(targetType, Number(form.getFieldValue('targetValue')), Number(form.getFieldValue('baselineValue')));
        setTargetValueForPercentType(Number.isNaN(value) ? 0 : value);
        }
    }, [targetType, triggerTargetcalulation, props.open]);

    React.useEffect(() => {
        if (props.open && !props.isEdit) {
            form.resetFields();
            setTableData([]);
            setYearSpecificTargetValue(null);
            setYearOfSpecificTargetValue(null);
            setBusinessUnit(null);
        }
    }, [props.open]);

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading='Setup targets'
            width='fit-content'
            footer={
                <>
                    <BEButton
                        className='primary'
                        size='large'
                        loading={loading}
                        onClick={() => {
                            if (checkAllBUValueExceedsCompanyValue()) {
                                form.submit();
                            } else {
                                setOpenModal(true);
                            }
                        }}
                    >
                        {props.isEdit ? 'Update target' : 'Add target'}
                    </BEButton>
                    <BEButton
                        size='large'
                        onClick={() => props.setOpen(false)}
                    >
                        Cancel
                    </BEButton>
                </>
            }
        >
            <Form
                form={form}
                onFinish={handleFormSubmit}
            >
                {props.type === 'multi' && <Row>
                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }} >
                        <LableRequired>Year duration {"(based on years configured)"}</LableRequired>
                        <Row>
                            <Col>
                                <Form.Item
                                    name="startYear"
                                    rules={[{ required: true, message: "Please select start year" }]}
                                >
                                    <Select
                                        style={{ width: 190, marginRight: 20 }}
                                        size="large"
                                        placeholder="Start year"
                                        onChange={(value) => setStartYear(Number(value))}
                                        options={existingYears.map((year) => {
                                            return { value: year, label: isReportingYearEndDecember ? `${year}` : `${year}-${year + 1}` }
                                        })}
                                    />

                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="endYear"
                                    rules={[{ required: true, message: "Please select end year" }]}
                                >
                                    <Select
                                        style={{ width: 190 }}
                                        size="large"
                                        placeholder="End year"
                                        onChange={(value) => setEndYear(Number(value))}
                                        options={existingYears.filter((year) => year > startYear)
                                            .map((year) => {
                                                return { value: year, label: isReportingYearEndDecember ? `${year}` : `${year}-${year + 1}` }
                                            })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Row>}
                <Row>
                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }} >
                        <LableRequired>Target Type</LableRequired>
                        <Form.Item
                            name="targetType"
                            rules={[{ required: true, message: "Please select target type" }]}
                        >
                            <Select
                                style={{ width: 400 }}
                                size='large'
                                value={targetType}
                                // defaultValue={props.targetData.target_type}
                                onChange={handleChange}
                                options={options}
                            />
                        </Form.Item>
                    </div>
                </Row>

                {(targetType === 'C' || targetType === 'D') &&
                    <Row>
                        <div style={{ display: "flex", flexDirection: "column" }} >
                            <LableRequired>Baseline value</LableRequired>

                            <Form.Item
                                name="baselineValue"
                                rules={[{ required: true, message: "Please enter numeric value" }]}
                            >
                                <InputNumber
                                    onBlur={() => setTriggerTargetcalulation(!triggerTargetcalulation)}
                                    style={{ width: "400px" }}
                                    size='large'
                                />
                            </Form.Item>
                        </div>
                    </Row>
                }

                {props.level === 'business-unit' &&
                    <Row>
                        <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }} >
                            <LableRequired>Business unit</LableRequired>
                            <Form.Item
                                name="businessUnit"
                                rules={[{ required: true, message: "Please select business unit" }]}
                            >
                                <Select
                                    style={{ width: 400 }}
                                    size='large'
                                    value={businessUnit}
                                    // defaultValue={props.targetData.target_type}
                                    onChange={(val) => setBusinessUnit(val)}
                                    options={businessUnits?.data.map((unit: any) =>
                                        ({ value: unit.id, label: unit.name })
                                    )}
                                />
                            </Form.Item>
                        </div>
                    </Row>}

                <Row>
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <LableRequired>{(targetType === 'C' || targetType === 'D') ? "Target %" : "Target value"}</LableRequired>
                        <Form.Item
                            name="targetValue"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter numeric value"
                                }
                            ]}
                        >
                            <InputNumber
                                onBlur={() => setTriggerTargetcalulation(!triggerTargetcalulation)}
                                style={{ width: "400px" }}
                                size='large'
                            />
                        </Form.Item>
                    </div>
                </Row>

                {(targetType === 'C' || targetType === 'D') &&
                    <Row>
                        <div style={{ display: "flex", flexDirection: "column" }} >
                            <LableRequired>Target value</LableRequired>

                            <Form.Item>
                                <BEInput
                                    style={{ width: "400px" }}
                                    size='large'
                                    disabled={true}
                                    value={targetvalueForPercentType}
                                />
                            </Form.Item>

                        </div>
                    </Row>
                }

                <Row>
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <LableRequired>Alert threshold in %</LableRequired>
                        <Form.Item
                            name="progressThreshold"
                            rules={[{ required: true, message: "Please enter numeric value" }]}
                        >
                            <InputNumber
                                style={{ width: "400px" }}
                                size='large'
                            />
                        </Form.Item>
                    </div>
                </Row>

                {props.type === 'multi' &&
                    <>
                        <Row>
                            <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }} >
                                <p>Add year specific target values</p>
                                <div>
                                    <BEInput
                                        size='large'
                                        placeholder={'Enter target value'}
                                        value={yearSpecificTargetValue}
                                        onChange={(e) => setYearSpecificTargetValue(e.target.value)}
                                        searchForColumns={yearValues}
                                        columnSearchValue={yearOfSpecificTargetValue}
                                        onChangeColumnSearch={(val: any) => setYearOfSpecificTargetValue(yearValues[val])}
                                        style={{ width: "280px", marginRight: "20px" }}
                                        selectPlaceholder='Year'
                                    />
                                    <BEButton
                                        className='primary'
                                        style={{ width: "100px" }}
                                        size='large'
                                        onClick={addYearSpecificTargetValueToTable}
                                    >
                                        Add
                                    </BEButton>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div style={{ width: "100%" }} >
                                <BETable
                                    data={tableData}
                                    columns={columns}
                                    pagination
                                />
                            </div>
                        </Row>
                    </>
                }
            </Form>
            <BEConfirmModal
                title="Confirm"
                message="The targets set at business unit level for this metric has exceeded the overall company level target. Do you want to proceed?"
                visible={openModal}
                setVisible={setOpenModal}
                onConfirm={async () => {
                    form.submit();
                }}
            />
        </BEDrawer >
    );
}