import { ColumnsType } from "antd/es/table";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { AddCommaToNumber } from "../../../../Config/Functions/UsefullFunctions";
import { Progress } from "antd";
import { PrimaryTheme } from "../../../../Config/Theme/theames";
import EditIcon from "../../../../Components/BEIcons/EditIcon";
import { BEInput } from "../../../../Components/BEFormItems/BEInput";
import { BEButton } from "../../../../Components/BEFormItems/BEButton";
import { BETable } from "../../../../Components/BETable";
import React from "react";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import { metricType } from "../../../../Redux/Types/dataManagerTypes";
import { MyMetricTargetsType } from "../../../../Redux/Types/targetTypes";
import { deleteMyDerivedMetricTarget, deleteMyMetricTarget, getMyDerivedMetricTargets, getMyMetricTargets } from "../../Actions";
import { TargetsTrackerCards } from "../Tracker";
import { SelectMetric } from "../../Views/Drawers/SelectMetrics";
import { SetupTarget } from "../../Views/Drawers/SetupTargets";
import DeleteIcon from "../../../../Components/BEIcons/DeleteIcon";
import BEConfirmModal from "../../../../Components/BEConfirmModal";
import dayjs from "dayjs";

export interface ITargetsTableProps {
  type: string; // 'single' | 'multi'
  level: string; // 'company' | 'business-unit'
}

export function TargetsTable(props: ITargetsTableProps) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [openSetuptargets, setOpenSetupTargets] = React.useState(false);
  const [targetData, setTargetData] = React.useState<any>({});
  const [page, setPage] = React.useState(1);
  const [tableData, setTableData] = React.useState<any>([]);
  const [filteredTargets, setFilteredTargets] = React.useState(tableData);
  const [load, setLoad] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const myMetricTargets = useTypedSelector(state => state.targets.MyMetricTargets);
  const MyDerivedMetricTargets = useTypedSelector(state => state.targets.MyDerivedMetricTargets);
  const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
  const derivedMetrics = useTypedSelector(state => state.onBoarding.metrics.derivedMetrics);
  const assessmentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits?.data);
  const yearEndDate = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);
  const [isReportingYearEndDecember, setIsReportingYearEndDecember] = React.useState(false);
  const conicColors = { '70%': PrimaryTheme.primaryGreen, '100%': "#FFBF00" };
  const threeColors = { '80%': PrimaryTheme.primaryGreen, '85%': "#FFBF00", '100%': "#E35335" };

  const options = [
    { value: 'A', label: 'absolute increase' },
    { value: 'B', label: 'absolute decrease' },
    { value: 'C', label: '% increase' },
    { value: 'D', label: '% decrease' }
  ];

  React.useEffect(() => {
    // Extract reporting year end month and day, if 31st December, then set the year as it is, else set the year as year - 1
    const yearEndMonth = dayjs(yearEndDate).format('MM-DD').split('-')[0];
    const yearEndDay = dayjs(yearEndDate).format('MM-DD').split('-')[1];
    const yearEndMonthDay = `${yearEndMonth}-${yearEndDay}`;
    const yearEndMonthDay31Dec = '12-31';
    if (yearEndMonthDay === yearEndMonthDay31Dec) {
      setIsReportingYearEndDecember(true);
    }
  }, [yearEndDate]);

  const columns: ColumnsType<any> = [
    {
      title: 'Sr. No.',
      key: 'sno',
      render: (text, object, index) => (page - 1) * 10 + index + 1
    },
    {
      title: 'Metric Name',
      dataIndex: 'title'
    },
    {
      title: 'Start Year',
      dataIndex: 'start_year',
      hidden: props.type === 'single',
      render: (text, record) => isReportingYearEndDecember ? record.start_year : `${record.start_year}-${record.start_year+1}`
    },
    {
      title: 'End Year',
      dataIndex: 'end_year',
      hidden: props.type === 'single',
      render: (text, record) => isReportingYearEndDecember ? record.end_year : `${record.end_year}-${record.end_year+1}`
    },
    {
      title: 'Business Unit',
      dataIndex: 'business_unit',
      render: (text, record) => record.business_unit && businessUnits?.find((item) => item.id === record.business_unit)?.name,
      hidden: props.level === 'company'
    },
    {
      title: 'Current Value',
      dataIndex: 'current_value',
      render: (text, record) => AddCommaToNumber(Number(text), false, true, true, 2, true) + ` ${record.unit}`
    },
    {
      title: 'Type',
      dataIndex: 'target_type',
      render: (text, record) => (options.filter((item) => item.value === record.target_type)[0].label)
    },
    {
      title: 'Target Value',
      dataIndex: 'target_value',
      render: (text, record) => AddCommaToNumber(Number(text), false, true, true) + ` ${record.unit}`
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      render: (text, record) =>
        <div style={{ display: "flex", flexDirection: "column", width: 180 }}>
          <div style={{ display: "flex", flexDirection: "row", fontSize: "small", lineHeight: "10px" }}>
            {/* <span>0</span> */}
            {/*<div style={{flexGrow: "0.83"}} /><span>{AddCommaToNumber(Number(getTargetValue(record)), false, true)}{" "}{getMetricUnit(record.title)}</span>*/}
          </div>
          <Progress
            strokeColor={record.progress < 70 ?
              PrimaryTheme.primaryGreen :
              (record.progress * 1.2) < 95 ? conicColors : threeColors}
            percent={record.progress} size={[180, 10]}
            showInfo={false}
          />
          {/*
            <div style={{ display: "flex", flexDirection: "row", fontSize: "small", lineHeight: "10px" }}>
              <div style={{ flexGrow: "0.8" }} />
              <span style={{ cursor: "pointer" }}><ToolTip title='Target'>^</ToolTip></span>
            </div>
          */}
        </div>
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <div style={{ display: "flex", gap: "1rem", flexDirection: "row", cursor: "pointer" }}>
          <div style={{ width: "1rem" }} onClick={() => {
            setTargetData(record);
            setIsEdit(true);
            setOpenSetupTargets(true);
          }}>
            <EditIcon inheritSize={true} stroke={PrimaryTheme.primaryGreyDark} />
          </div>
          <div style={{ width: "1rem" }} onClick={() => {
            setTargetData(record)
            setOpenDelete(true)
          }}>
            <DeleteIcon inheritSize={true} stroke={PrimaryTheme.primaryGreyDark} />
          </div>
        </div >
      )
    }
  ];

  const changePage = (page: number) => {
    setPage(page)
  };

  React.useEffect(() => {
    const directTargetData = myMetricTargets?.data
      .filter((item: MyMetricTargetsType) => {
        return props.type === 'single'
          ?
          item.end_year === assessmentYear &&
          item.start_year === assessmentYear
          :
          item.end_year >= assessmentYear &&
          item.start_year <= assessmentYear &&
          item.start_year !== item.end_year
      })
      .filter((item: MyMetricTargetsType) => {
        return props.level === 'company'
          ?
          item.business_unit === null
          :
          item.business_unit !== null
      })
      .map((item: MyMetricTargetsType) => {
        return {
          id: item.id,
          title: metrics?.data?.find((metric) => metric.id === item.metric)?.title,
          unit: metrics?.data?.find((metric) => metric.id === item.metric)?.unit,
          current_value: item.current_value,
          target_type: item.target_type,
          target_value: item.target_value,
          progress: item.progress,
          start_year: item.start_year,
          end_year: item.end_year,
          business_unit: item.business_unit,
          metric: item.metric,
          baseline: item.baseline,
          progress_threshold: item.progress_threshold,
          isDerived: false
        }
      });

    const derivedTargetData = MyDerivedMetricTargets?.data
      .filter((item: any) => {
        return props.type === 'single'
          ?
          item.end_year === assessmentYear &&
          item.start_year === assessmentYear
          :
          item.end_year >= assessmentYear &&
          item.start_year <= assessmentYear &&
          item.start_year !== item.end_year
      })
      .filter((item: any) => {
        return props.level === 'company'
          ?
          item.business_unit === null
          :
          item.business_unit !== null
      })
      .map((item: any) => {
        return {
          id: item.id,
          title: derivedMetrics?.data?.find((metric) => metric.id === item.derived_metric)?.title,
          unit: derivedMetrics?.data?.find((metric) => metric.id === item.derived_metric)?.unit,
          current_value: item.current_value,
          target_type: item.target_type,
          target_value: item.target_value,
          progress: item.progress,
          start_year: item.start_year,
          end_year: item.end_year,
          business_unit: item.business_unit,
          metric: item.derived_metric,
          baseline: item.baseline,
          progress_threshold: item.progress_threshold,
          isDerived: true
        }
      });
    const data = [...directTargetData, ...derivedTargetData];
    setTableData(data);
  }, [myMetricTargets, metrics, assessmentYear, MyDerivedMetricTargets, derivedMetrics]);

  React.useEffect(() => {
    if (myMetricTargets?.status === 'idle') {
      dispatch(getMyMetricTargets());
    }
    if (MyDerivedMetricTargets?.status === 'idle') {
      dispatch(getMyDerivedMetricTargets());
    }
  }, [myMetricTargets, MyDerivedMetricTargets]);

  React.useEffect(() => {
    setFilteredTargets(tableData);
  }, [tableData]);

  return (
    <div style={{ padding: "1rem 0", margin: "0 1.5rem" }}>
      <TargetsTrackerCards targetsData={filteredTargets} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <BEInput
          style={{ width: "313px" }}
          search
          placeholder="Search Targets"
          size="large"
          onChange={(e) => {
            const value = e.target.value?.toLowerCase();
            const filteredData = value !== '' ? tableData?.filter((item: any) => {
              return item.title?.toLowerCase().includes(value);
            }) : tableData;
            setFilteredTargets(filteredData);
          }}
        />
        <BEButton
          className="primary"
          style={{ marginLeft: "auto" }}
          size="large"
          onClick={() => {
            setOpen(true);
          }}
        >
          Add targets
        </BEButton>
      </div>
      <BETable
        rowKey='sno'
        loading={load}
        columns={columns}
        handleChangePage={changePage}
        data={filteredTargets}
        pagination
      />
      <SelectMetric
        open={open}
        setOpen={setOpen}
        type={props.type}
        level={props.level}
      />
      <SetupTarget
        open={openSetuptargets}
        setOpen={setOpenSetupTargets}
        selectedMetric={{
          id: targetData.metric,
          title: targetData.title,
          target_type: targetData.target_type,
          target_value: targetData.target_value,
          current_value: targetData.current_value,
          start_year: targetData.start_year,
          end_year: targetData.end_year,
          business_unit: targetData.business_unit,
          progress: targetData.progress,
          targetId: targetData.id,
          isDerived: targetData.isDerived,
          baseline: targetData.baseline,
          progress_threshold: targetData.progress_threshold
        }}
        type={props.type}
        level={props.level}
        isEdit={isEdit}
      />
      <BEConfirmModal
        title="Confirm Delete"
        message="Are you sure you want to delete the target metrics?"
        visible={openDelete}
        setVisible={setOpenDelete}
        onConfirm={async () => {
          if (targetData.isDerived) {
            await dispatch(deleteMyDerivedMetricTarget(targetData.id));
          } else {
            await dispatch(deleteMyMetricTarget(targetData.id));
          }
        }}
      />
    </div>
  );
}
