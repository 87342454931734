import * as React from 'react';
import { BEDrawerType1 } from '../../../../../Components/BEDrawer/BEDrawerType1';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { BEProgressBar } from '../../../../../Components/BEProgress';
import './style.scss';
import { RightSectionEditReport } from './RightBody';
import { LeftSectionEditReport } from './LeftBody';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { changeStatusOfAllTopicsIfAllFilled, exportReport, getReportData, getReportingEvidence, getReportingTopicStatus, getTemplateTopics, getTopicsByFramework, patchReportTemplate, postReportAutoFill } from '../../../Actions/template';
import { setAllSelectedTemplateTopics, setCurrentGRESBSection, setSelectedTemplateTopics } from '../../../../../Redux/ReportingReducer';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { TemplateSelectedTopicTypes, reportDataTypes, ReportingTopicStatusTypes, omittedReportTopicsTypes } from '../../../../../Redux/Types/reportingTypes';
import { BEMessage } from '../../../../../Components/BEMessage';
import { Modal } from 'antd';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { ChooseAssets } from '../ChooseAsset';
import { arrangeGresbDataSectionWise, calculateAssetProgress, getGresbEntities, getGresbPortalEntityData, getGRESBSlugData } from '../../../Actions/gresbActions';
import ToolTip from '../../../../../Components/BEToolTip';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { checkCeleryTaskStatusPeriodically, getCeleryTaskResult } from '../../../../../views/Actions/HomeActions';


export interface IEditReportProps {
    open: boolean;
    setOpen: Function;
}

export function EditReport(props: IEditReportProps) {
    const dispatch = useAppDispatch()
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const gresbSlugData = useTypedSelector(state => state.reporting.gresbSlugData);
    const currentGRESBSection = useTypedSelector(state => state.reporting.currentGRESBSection);
    const selectedGRESBAsset = useTypedSelector(state => state.reporting.selectedGRESBAsset);
    const templateDrafts = useTypedSelector(state => state.reporting.TemplateDrafts);
    const gresbEntities = useTypedSelector(state => state.reporting.gresbEntityData);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingDownload, setLoadingDownload] = React.useState<boolean>(false);
    const [loadingAutoFill, setLoadingAutoFill] = React.useState<boolean>(false);
    const [openInfoModal, setOpenInfoModal] = React.useState<boolean>(true);
    const [loadingMarkForReview, setLoadingMarkForReview] = React.useState<boolean>(false)
    const allSelectedTopics = useTypedSelector(state => state.reporting.allSelectedTemplateTopics);
    const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const omittedReportTopics = useTypedSelector(state => state.reporting.omittedReportTopics);
    const reportData = useTypedSelector(state => state.reporting.reportData);
    const topicStatus = useTypedSelector(state => state.reporting.topicStatus);
    const [progress, setProgress] = React.useState<number>(0);
    const [openChooseAsset, setOpenChooseAsset] = React.useState<boolean>(false);
    const [searchTopicValue, setSearchTopicValue] = React.useState<string>('');
    const [searchDropDown, setSearchDropdown] = React.useState<boolean>(false)
    const [listItems, setListItems] = React.useState<any[]>([]);
    const [currentItemId, setCurrentItemId] = React.useState<string>();
    const role = localStorage.getItem('role');

    const getSelectedTopics = async () => {
        if (currentReport?.template) {
            setLoading(true)
            const res = await dispatch(getTemplateTopics(currentReport?.template, false))
            setLoading(false)
            if (res)
                dispatch(setAllSelectedTemplateTopics({
                    status: 'success',
                    data: res
                }))
        }
    }
    const handleSearchBlur = () => {
        if (!currentItemId) {
            setSearchDropdown(false);
        }
    };
    const handleSearchFocus = () => {
        setSearchDropdown(true);
    };
    React.useEffect(() => {
        setSearchDropdown(false);
        if (gresbSlugData?.status === 'idle')
            dispatch(getGRESBSlugData());
    }, [props.open])

    React.useEffect(() => {
        const uniqueCodes = Array.from(new Set(allSelectedTopics?.data
            .filter((template: TemplateSelectedTopicTypes) => template.topic.toLowerCase().includes(searchTopicValue.toLowerCase()) || template.disclosure_code.includes(searchTopicValue.toLowerCase())).sort((a, b) => a.topic_id - b.topic_id).map((item) => {
                return item.unique_code
            })
        ))
        const uniqueItemsMap = new Map<string, TemplateSelectedTopicTypes>();
        allSelectedTopics?.data.forEach((item: TemplateSelectedTopicTypes) => {
            if (uniqueCodes.includes(item.unique_code) && !uniqueItemsMap.has(item.unique_code)) {
                uniqueItemsMap.set(item.unique_code, item);
            }
        });
        const filteredItems = Array.from(uniqueItemsMap.values());
        dispatch(setSelectedTemplateTopics({
            status: 'success',
            data: filteredItems
        }))
        setListItems(filteredItems)
    }, [searchTopicValue, allSelectedTopics])

    React.useEffect(() => {
        if (allSelectedTopics?.status === 'success') {
            if (templateDrafts?.data?.find((item) => item.id === currentReport?.template)?.framework === 'GRESB') {
                if (currentGRESBSection === 'entity') {
                    dispatch(setSelectedTemplateTopics({
                        status: 'success',
                        data: allSelectedTopics?.data.filter((topic) => topic.dimension !== 'nan')
                    }));
                } else {
                    dispatch(setSelectedTemplateTopics({
                        status: 'success',
                        data: allSelectedTopics?.data.filter((topic) => topic.dimension === 'nan' && topic.asset === selectedGRESBAsset?.id)
                    }))
                }
            } else {
                dispatch(setSelectedTemplateTopics({
                    status: 'success',
                    data: allSelectedTopics?.data
                }))
            }
        }
    }, [allSelectedTopics, currentGRESBSection, props.open, currentReport])

    React.useEffect(() => {
        if (props.open) {
            getSelectedTopics();
            if (currentReport?.id) {
                dispatch(getReportData(currentReport?.id))
                dispatch(getReportingEvidence(currentReport?.id))
                dispatch(getReportingTopicStatus(currentReport?.id))
            }
            if (currentGRESBSection === 'entity') {
                dispatch(getGresbEntities());
            }
        }
    }, [props.open, currentReport])

    React.useEffect(() => {
        if (templateDrafts?.data?.find((item) => item.id === currentReport?.template)?.framework === "GRESB") {
            if (currentGRESBSection === 'entity') {
                // const total = Object.keys(gresbEntityAccordionStatus?.data).length - 13;
                // let filled = 0;
                // Object.keys(gresbEntityAccordionStatus?.data).forEach((key) => {
                //     if (gresbEntityAccordionStatus?.data[key]) filled++;
                // })
                // setProgress(Number(((filled / total) * 100).toFixed(2)));
                const progress = gresbEntities?.data?.find((entity) => entity.report === currentReport?.id)?.real_estate_assessment_progress;
                setProgress(progress || 0);
            } else {
                setProgress(dispatch(calculateAssetProgress(selectedGRESBAsset?.id || 0)));
            }
        } else {
            // dont calculate the checkbox topics in the progress calculations as they can be default false.
            const topicsWithUnitCheckbox = allSelectedTopics?.data.filter((topic: any) => topic.unit === 'Checkbox').length
            let tempProgress: number = 0;
            let allUniqueCodes = new Set<string>(allSelectedTopics?.data.map((topic: TemplateSelectedTopicTypes) => topic.unique_code));
            let omitedUniqueCodes = new Set<string>(omittedReportTopics?.data.map((topic: omittedReportTopicsTypes) => allSelectedTopics?.data?.find((item: TemplateSelectedTopicTypes) => item.id === topic.topic_id)?.unique_code || ''));
            let filledUniqueCodes = topicStatus?.data.filter((item: ReportingTopicStatusTypes) => !omitedUniqueCodes.has(item.unique_code) && (item?.status === 1 || item?.status === 2)).length

            tempProgress = Number(
                (((filledUniqueCodes) /
                    (allUniqueCodes.size - omitedUniqueCodes.size)) * 100).toFixed(2)
            )
            // tempProgress =
            // Number(((Object.keys(reportData?.data).length - dataKeysWithUnitCheckbox)
            //     /
            //     (allSelectedTopics?.data.filter((item) => omittedReportTopics?.data.
            //     filter((topic) => topic.topic_id === item.id &&
            //     topic.report === currentReport?.id &&
            //     topic.section === currentSection)).length - topicsWithUnitCheckbox)
            //     * 100).toFixed(2))
            if (allSelectedTopics?.data.length - topicsWithUnitCheckbox === 0) tempProgress = 100;
            setProgress(Number(tempProgress))
        }
    }, [allSelectedTopics, reportData, omittedReportTopics, topicStatus])

    const handleMarkForReview = async () => {
        setLoadingMarkForReview(true);
        await dispatch(patchReportTemplate(currentReport?.id || 0, { stage: 4 }, true))
        BEMessage.success('Report marked for review successfully.')
        setLoadingMarkForReview(false)
    }

    const getNestedEntityData = async () => {
        if (allSelectedTopics?.status !== 'idle') {
            const data = await dispatch(arrangeGresbDataSectionWise());
        }
    }

    React.useEffect(() => {
        getNestedEntityData();
    }, [props.open, allSelectedTopics]);

    return (
        <BEDrawerType1
            open={props.open}
            setOpen={props.setOpen}
            heading='Edit Report'
            subHeading={currentGRESBSection === 'asset' ? selectedGRESBAsset?.name : currentReport?.name}
            leftFooter={
                <div style={{ display: "flex", gap: "1rem" }}>
                    {currentGRESBSection === null && templateDrafts?.data?.find((item) => item.id === currentReport?.template)?.framework !== 'GRESB' &&
                        <BEButton className='primary' loading={loadingDownload}
                            onClick={async () => {
                                setLoadingDownload(true)
                                await dispatch(exportReport(currentReport))
                                setLoadingDownload(false)
                            }}
                        >
                            Download Report
                        </BEButton>
                    }
                    {currentGRESBSection === null && templateDrafts?.data?.find((item) => item.id === currentReport?.template)?.framework !== 'GRESB' &&
                        <BEButton
                            disabled={progress < 100 || (role !== 'SUPER_ADMIN' && role !== 'ADMIN')}
                            loading={loadingMarkForReview}
                            onClick={handleMarkForReview}
                        >
                            Mark for Review
                        </BEButton>
                    }
                    {currentGRESBSection !== null && templateDrafts?.data?.find((item) => item.id === currentReport?.template)?.framework === 'GRESB' ?
                        <BEButton
                            className='primary'
                            onClick={() => {
                                if (currentGRESBSection === 'entity') {
                                    setOpenChooseAsset(true);
                                    props.setOpen(false);
                                    dispatch(setCurrentGRESBSection('asset'));
                                } else {
                                    dispatch(setCurrentGRESBSection('entity'))
                                }
                            }}
                        >
                            Switch to {currentGRESBSection === 'entity' ? 'Asset' : 'Entity'}
                        </BEButton>
                        : null
                    }
                </div>
            }
            leftBodyFooter={
                <BEProgressBar
                    width='150%'
                    textColor='black'
                    valuePercent={progress}
                />
            }
            headerRight={
                <div className='right-header'>
                    {/* <div className='last-updated'>
                        <span>Last Updated</span>
                        <span>  12/12/2023, 16:43</span>
                    </div> */}
                    {
                        currentGRESBSection === 'entity' &&
                        <BEButton className='primary' size='middle' loading={loadingAutoFill}
                            onClick={async () => {
                                setLoadingAutoFill(true);
                                const taskIds = await dispatch(getGresbPortalEntityData(currentReport?.id || 0));
                                await dispatch(checkCeleryTaskStatusPeriodically(taskIds, 90000, async () => {
                                    const reportData = await dispatch(getReportData(currentReport?.id || 0))
                                    BEMessage.success('Data imported successfully.');
                                    setLoadingAutoFill(false)
                                }));
                            }}
                        >
                            <ToolTip title='Import entity data from GRESB portal'>
                                Import data
                            </ToolTip>
                        </BEButton>
                    }
                    {
                        currentGRESBSection === null &&

                        <>
                            <div onMouseOut={() => setCurrentItemId('')}>
                                <BEInput
                                    search
                                    onFocus={handleSearchFocus}
                                    value={searchTopicValue}
                                    onBlur={handleSearchBlur}
                                    placeholder='Search for a Topic'
                                    onChange={(e) => setSearchTopicValue(e.target.value || '')}
                                />
                                {
                                    searchDropDown && <div className='tabSearchDropdown'>
                                        {
                                            listItems.map((template: TemplateSelectedTopicTypes) => {
                                                return <div onMouseOver={() => setCurrentItemId(template.disclosure_code)} onClick={() => {
                                                    console.log(template.topic)
                                                    setSearchTopicValue(template.topic)
                                                }} className='list-items'>
                                                    {template.disclosure_code} {template.topic}
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            <BEButton className='primary' size='middle' loading={loadingAutoFill}
                                onClick={async () => {
                                    setLoadingAutoFill(true);
                                    const taskId = await dispatch(postReportAutoFill(currentReport?.id || 0));
                                    if (taskId) {
                                        await dispatch(checkCeleryTaskStatusPeriodically([taskId], 20000, async () => {
                                            const dataLength = await dispatch(getCeleryTaskResult(taskId));
                                            if (dataLength > 0) {
                                                if (currentReport?.stage === 1) await dispatch(patchReportTemplate(currentReport?.id || 0, { stage: 2 }, true));
                                                let newData: any = await dispatch(getReportData(currentReport?.id || 0))
                                                if (newData) await dispatch(changeStatusOfAllTopicsIfAllFilled(newData?.data))
                                                if (Array.from(new Set(newData?.data.map((item: any) => item.topic))).length === selectedTopics?.data.length) {
                                                    await dispatch(patchReportTemplate(currentReport?.id || 0, { stage: 3 }, true))
                                                    BEMessage.success('Report is completed successfully')
                                                }
                                                BEMessage.success(`${dataLength} data points auto filled successfully`)
                                            } else if (dataLength === 0) {
                                                BEMessage.info('No data found to autofill');
                                            } else {
                                                BEMessage.error('Error in autofilling data');
                                            }
                                            setLoadingAutoFill(false);
                                        }))
                                    }
                                }}>
                                Autofill
                            </BEButton>
                        </>

                    }
                    <Modal title="" closeIcon={null} open={loadingAutoFill} onOk={() => { }} onCancel={() => { }} footer={[]}
                        width={400}
                        style={{ color: PrimaryTheme.primaryGreen, textAlign: 'center', padding: '2rem' }}
                    >
                        <h2>Autofilling...</h2>
                        <GlobalLoader height='20vh' />
                        <h4>Please wait for few minutes!</h4>
                    </Modal>
                </div>}

            leftBody={
                <LeftSectionEditReport />
            }
            rightBody={
                loading ? <GlobalLoader height='80vh' /> :
                    <>
                        <RightSectionEditReport />
                        <ChooseAssets open={openChooseAsset} setOpen={setOpenChooseAsset} />
                        {currentGRESBSection === 'entity' && props.open &&
                            <Modal
                                title="Instructions on creating a GRESB report with Breathe ESG."
                                open={openInfoModal}
                                onCancel={() => setOpenInfoModal(false)}
                                footer={null}

                            >
                                <p>Please ensure the following:</p>
                                <ol>
                                    <li>Maintain an active connection with the GRESB portal, by signing in to your GRESB account, on Breathe ESG</li>
                                    <li>The status of your connection with GRESB on the Breathe ESG platform should be 'Connected'</li>
                                    <li>Avoid editing the report simultaneously on both platforms - best practice is to choose a platform for edits.</li>
                                    <li>If you have entered data on the GRESB platform then, please click the "Import" button to sync data between the two platforms.</li>
                                    <li>Once the report is completed on Breathe ESG, the review and final submission can be done on the GRESB Portal.</li>
                                </ol>
                            </Modal>
                        }
                    </>
            }
        />
    );
}

