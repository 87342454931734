import React from 'react'
import TopSection from '../TopSection';
import { Navigate, Outlet } from 'react-router-dom';

const DashboardLayout = () => {
  const role = localStorage.getItem('role');
  if(role === 'CONTRIBUTOR') return <Navigate to="/home/data-manager" />
  return (
    <div style={{padding: "0 1.5rem"}}>
      <TopSection />
      <Outlet />
    </div>
  );
}

export default DashboardLayout