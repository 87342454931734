import React, { useEffect, useState } from "react";
import { IStep1Props } from "../Step1";
import { StepCollapse } from "../../../../../../Components/BECollapse/StepCollapse";
import { Col, Row } from "antd";
import { BEInput } from "../../../../../../Components/BEFormItems/BEInput";
import { BEButton } from "../../../../../../Components/BEFormItems/BEButton";
import { useAppDispatch } from "../../../../../../Config/Hooks/useAppDispatch";
import { addSupplierAssessmentRef, getSupplierSurveyRefs, getSuppliers, deleteSupplierRef } from "../../../../Actions/supplierAction";
import { ColumnsType } from "antd/es/table";
import { useTypedSelector } from "../../../../../../Config/Hooks/useTypedSelector";
import { BETable } from "../../../../../../Components/BETable";
import { setCreateSurveyStep } from "../../../../../../Redux/MatirialityReducer";
import AddSuppliers from "../../AddSuppliers";

export interface IStep2Props extends IStep1Props { }

const Step3 = (props: IStep2Props) => {
  const dispatch = useAppDispatch();
  const allSuppliers = useTypedSelector((state) => state.supplier.Suppliers)
  const supplierSurveyRefs = useTypedSelector(state => state.supplier.SuppliersSurveyRefs);
  const currentAssessment = useTypedSelector((state) => state.supplier.currentAssessment)
  const [addSupplier, setAddSupplier] = useState(false)
  const [selectedSuppliers, setSelectedSuppliers] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [filteredSuppliers, setFilteredSuppliers] = useState<any>([])
  const createSurveyStep = useTypedSelector(state => state.supplier.createSurveyStep);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: "SUPPLIER NAME",
      dataIndex: "name",
    },
    {
      title: "POC",
      dataIndex: "poc",
    },
    {
      title: "POC EMAIL",
      dataIndex: "email",
    },
    {
      title: "INDUSTRY",
      dataIndex: "industry",
    },
    // {
    //   title: "LAST COMPLIANCE",
    //   dataIndex: "last_compliance_fy",
    //   render: (value) => {
    //     return !value ? "-" : value;
    //   },
    // },
  ];

  useEffect(() => {
    if (props.open) {
      dispatch(getSuppliers())
    }
  }, [props.open]);


  const handleAddSuppliers = async () => {
    if (selectedSuppliers.length === 0) {
      dispatch(setCreateSurveyStep(4));
      props.setOpen(false)
      return
    }
    setLoading(true)
    const body: Array<any> = []
    selectedSuppliers.forEach((supplier: any) => {
      body.push({
        supplier: supplier,
        assessment: currentAssessment?.id
      })
    })
    // console.log(body)
    await dispatch(addSupplierAssessmentRef(body))
    setLoading(false)
  }

  useEffect(() => {
    if (allSuppliers?.status === 'success' && supplierSurveyRefs?.status === 'success') {
      const list = allSuppliers?.data.filter((sup) => {
        return supplierSurveyRefs?.data.some((ref) => ref.supplier === sup.id)
      })
      setFilteredSuppliers(list);
    }
  }, [allSuppliers, supplierSurveyRefs])

  useEffect(() => {
    if (currentAssessment && props.open)
      dispatch(getSupplierSurveyRefs(Number(currentAssessment?.id)));
  }, [props.open, currentAssessment])

  return (
    <div>
      <StepCollapse
        step={3}
        heading="Select Suppliers"
        subheading="Select the topics which you want to include in this survey, and also define their order of priority for the company"
        open={props.open}
        setOpen={
          () =>
            dispatch(setCreateSurveyStep(
              createSurveyStep === 3 ? 0 : 3
            ))
        }
        disabled={
          currentAssessment === null ||
          currentAssessment.type === 'N/A'
        }
      >
        <div style={{ padding: "1rem" }}>
          {addSupplier && (
            <AddSuppliers
              open={addSupplier}
              setOpen={setAddSupplier}
            />
          )}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <BEInput
            style={{width:'313px'}}
              search
              placeholder="Search for a supplier"
              size="large"
              onChange={(e) => {
                const value = e.target.value?.toLowerCase();
                const filteredData = allSuppliers?.data?.filter(
                  (item: any) => {
                    return (
                      (item.name?.toLowerCase().includes(value) ||
                        item.poc?.toLowerCase().includes(value)) &&
                      supplierSurveyRefs?.data?.some((ref) => ref.supplier === item.id)
                    );
                  }
                );
                setFilteredSuppliers(filteredData);
              }}
            />
            <div style={{ display: "flex", gap: "1rem",marginLeft:'auto' }}>
              
              <BEButton
                size="large"
                className="primary"
                onClick={() => setAddSupplier(true)}
              >
                + Add Supplier
              </BEButton>
            </div>
          </div>


          <div style={{ margin: "1rem auto" }}>
            <BETable
              handleDelete={async (supplier: any) => {
                await dispatch(deleteSupplierRef(
                  Number(supplierSurveyRefs?.data?.find((ref) => ref.supplier === supplier.id)?.id),
                  Number(currentAssessment?.id)))
              }}
              data={filteredSuppliers}
              columns={columns}
              loading={supplierSurveyRefs?.status === "loading"}
              pagination
            // disabledRows={[]}
            />
          </div>
        </div>

        <div style={{ margin: "1rem " }}>
          <BEButton
            className="primary"
            size="large"
            onClick={handleAddSuppliers}
            loading={loading}
          >
            Save
          </BEButton>
        </div>
        
      </StepCollapse>
    </div>
  );
};

export default Step3;
