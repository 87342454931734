import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { AppDispatch } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import {
    setMyDashboardMetrics,
    setMyDerivedDashboardMetrics,
} from "../../../Redux/DataManagerReducer";
import { setQuickSightDashboard } from "../../../Redux/CommonReducer";
import {
    DELETE_MY_DASHBOARD_METRICS,
    GET_MY_DASHBOARD_METRICS,
    GET_QUICKSIGHT_AUTHOR_DASHBOARD_DETAILS,
    GET_QUICKSIGHT_READER_DASHBOARD_DETAILS,
    GET_MY_DERIVED_DASHBOARD_METRICS,
    POST_MY_DERIVED_DASHBOARD_METRICS,
    PATCH_MY_DERIVED_DASHBOARD_METRICS,
    DELETE_MY_DERIVED_DASHBOARD_METRICS,
    PATCH_MY_DASHBOARD_METRICS,
    POST_MY_DASHBOARD_METRICS,
    GET_MY_DASHBOARD_ANALYTICS,
} from "../../../Utils/Routes/DashboardRoutes";

export const getMyDashboardMetrics = () => async (dispatch:AppDispatch) => {
    dispatch(setMyDashboardMetrics({
            status: "loading",
            data: []
        }));
    const userId = JSON.parse(localStorage.getItem('userInfo') || '{}').id;
    const [data, error] = await handleAPICall(GET_MY_DASHBOARD_METRICS());
    if (data) {
        dispatch(setMyDashboardMetrics({
            status: "success",
            data: data?.data
            .filter((item:any) => item.user === userId)
        }));
    }
    else{
        dispatch(setMyDashboardMetrics({
            status: "error",
            data: []
        }));
    }
}


export const postMyDashboardMetrics = (data:any, isDefault: boolean = false) => async (dispatch:AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_MY_DASHBOARD_METRICS(data));
    if (dataRes) {
        await dispatch(getMyDashboardMetrics());
        if (!isDefault)
        BEMessage.success("Metric Added Successfully");
        return true;
    }
    else{
        if (!isDefault)
        BEMessage.error("Something went wrong");
        return null
    }
}

export const deleteMyDashboardMetrics = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_MY_DASHBOARD_METRICS(id));
    if (dataRes) {
        BEMessage.success("Metric Deleted Successfully");
        dispatch(getMyDashboardMetrics());
    }
}


export const patchMyDashboardMetrics = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_MY_DASHBOARD_METRICS(id,data));
    if (dataRes) {
        dispatch(getMyDashboardMetrics());
    }
    else{
        BEMessage.error("Something went wrong");
    }
}

export const getQuickSightDashboardDetails = () => async (dispatch: AppDispatch) => {
    dispatch(setQuickSightDashboard({
        status: "loading",
        data: {}
    }));
    const role = localStorage.getItem('role');
    let data, error;
    if (role === 'SUPER_ADMIN') {
        [data, error] = await handleAPICall(GET_QUICKSIGHT_AUTHOR_DASHBOARD_DETAILS());
    } else {
        [data, error] = await handleAPICall(GET_QUICKSIGHT_READER_DASHBOARD_DETAILS());
    }
    if (data) {
        dispatch(setQuickSightDashboard({
            status: "success",
            data: data?.data
        }));
    } else {
        dispatch(setQuickSightDashboard({
            status: "error",
            data: {}
        }));
        
    }
}

export const getMyDerivedDashboardMetrics = () => async (dispatch:AppDispatch) => {
    dispatch(setMyDerivedDashboardMetrics({
            status: "loading",
            data: []
        }));
    const userId = JSON.parse(localStorage.getItem('userInfo') || '{}').id;
    const [data, error] = await handleAPICall(GET_MY_DERIVED_DASHBOARD_METRICS());
    if (data) {
        dispatch(setMyDerivedDashboardMetrics({
            status: "success",
            data: data?.data
            .filter((item:any) => item.user === userId)
        }));
    }
    else{
        dispatch(setMyDerivedDashboardMetrics({
            status: "error",
            data: []
        }));
    }
}

export const postMyDerivedDashboardMetrics = (data:any) => async (dispatch:AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_MY_DERIVED_DASHBOARD_METRICS(data));
    if (dataRes) {
        await dispatch(getMyDerivedDashboardMetrics());
        BEMessage.success("Metric Added Successfully");
        return true;
    }
    else{
        BEMessage.error("Something went wrong");
        return null
    }
}

export const deleteMyDerivedDashboardMetrics = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_MY_DERIVED_DASHBOARD_METRICS(id));
    if (dataRes) {
        BEMessage.success("Metric Deleted Successfully");
        dispatch(getMyDerivedDashboardMetrics());
    }
}

export const patchMyDerivedDashboardMetrics = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_MY_DERIVED_DASHBOARD_METRICS(id,data));
    if (dataRes) {
        dispatch(getMyDerivedDashboardMetrics());
    }
    else{
        BEMessage.error("Something went wrong");
    }
}


export const getDashoardAnalytics = (
    metricBcodes: string[],
    businessUnits: number[],
    startDate: string,
    endDate: string,
) => async (dispatch: AppDispatch) => {
    
    const [data, error] = await handleAPICall(GET_MY_DASHBOARD_ANALYTICS(metricBcodes, businessUnits, startDate, endDate));
    if (data) {
        return data.data;
    }
    else{
        return null
    }

}