import React from 'react'
import { TopSectionDM } from './Components/TopSectionDM';
import './style.scss';
import Tracker from './Tracker';
import { DataEntry } from './Components/DataEntry';
import { SetTableAndGraphDataDM } from './Components/SetTableAndGraphDataFunctionDM';
import { TopTabSearch } from './Components/DataEntry/TopTabsDM/TopTabSearch';
import { StatusFilter } from './Components/DataEntry/TopTabsDM/StatusFilter';
import { useTypedSelector } from '../../Config/Hooks/useTypedSelector';

const DataManager = () => {
    const [section, setSection] = React.useState<'dataEntry' | 'tracker'>('dataEntry')
    const myMetrics = useTypedSelector((state) => state.onBoarding.metrics.myMetricsPerYear);
    return (
        <div className="data-manager">
            <TopSectionDM setSection={setSection} section={section} />
            { section === 'dataEntry' && myMetrics?.status === 'success' &&
                <div className='filter-section'>
                    <TopTabSearch />
                    <StatusFilter />
                </div>
            }
            {
                section === 'dataEntry' ?
                    <DataEntry />
                    :
                    <Tracker />
            }
            <SetTableAndGraphDataDM />
        </div>
    );
}

export default DataManager;