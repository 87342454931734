import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { DashboardExperience, EmbeddingContext, FrameOptions, createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getQuickSightDashboardDetails } from '../../Actions';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import './style.scss';  
import { AnalyticsGraphMetrics } from '../Dashboard/AnalyticsGraphMetrics';
import { AnalyticsGraphBUs } from '../Dashboard/AnalyticsGraphBusinessUnits';
export interface IAmazonDashboardProps {
}

export function AmazonDashboard(props: IAmazonDashboardProps) {
  // const dashboardRef = useRef<any>(null);
  // const [dashboardId, setDashboardId] = useState('e664d53a-be07-42f8-87e1-e358812ddd8c');
  // const [embeddedDashboard, setEmbeddedDashboard] = useState<DashboardExperience>();
  // const [dashboardUrl, setDashboardUrl] = useState<string>('');
  // const [dashboardUrls, setDashboardUrls] = useState<string[]>([]);
  // const [embeddingContext, setEmbeddingContext] = useState<EmbeddingContext>();
  // const quickSightDashboardDetails = useTypedSelector(state => state.common.quickSightDashboard);
  // const dispatch = useAppDispatch();

  // useEffect(() => {
    // if (quickSightDashboardDetails?.status === 'idle') {
      // dispatch(getQuickSightDashboardDetails());
    // }
  // }, []);

  // useEffect(() => {
  //   if (quickSightDashboardDetails?.status === 'success') {
  //     setDashboardUrl(quickSightDashboardDetails?.data.EmbedUrl);
      // setDashboardUrls(quickSightDashboardDetails?.data?.map((dashboard: any) => dashboard.EmbedUrl));
  //   }
  // }, [quickSightDashboardDetails]);

  {/*  useEffect(() => {
    if (quickSightDashboardDetails?.status === 'idle') {
      dispatch(getQuickSightDashboardDetails());
    }
  }, []);

  useEffect(() => {
    if (quickSightDashboardDetails?.status === 'success') {
      setDashboardUrls(quickSightDashboardDetails?.data.map((dashboard: any) => dashboard.EmbedUrl));
    }
  }, [quickSightDashboardDetails]);

  const createContext = async () => {
    const context: EmbeddingContext = await createEmbeddingContext();
    setEmbeddingContext(context);
  }
  useEffect(() => {
    if (dashboardUrls.length > 0) {
      createContext();
    }
  }, [dashboardUrls])

  useEffect(() => {
    if (embeddingContext && quickSightDashboardDetails?.status === 'success') { embed(); }
  }, [embeddingContext, quickSightDashboardDetails])

  const embed = async () => {

    const options: FrameOptions = {
      url: dashboardUrls[0],
      container: dashboardRef.current,
      height: "100%",
      width: "100%",
    };

    const newEmbeddedDashboard: DashboardExperience = await embeddingContext?.embedDashboard(options) as DashboardExperience;
    setEmbeddedDashboard(newEmbeddedDashboard);
  };

  useEffect(() => {
    if (embeddedDashboard && quickSightDashboardDetails?.status === 'success') {
      embeddedDashboard.navigateToDashboard(dashboardId, {})
    }
  }, [dashboardId, quickSightDashboardDetails, embeddedDashboard])

  const changeDashboard = async (e: any) => {
    const dashboardId = e.target.value
    setDashboardId(dashboardId)
  }*/}
  // return (
  //   <div className='quicksight-container'>
  //     {/* {dashboardUrls.length > 0 &&
  //       dashboardUrls.map((url: string) => ( */}
  //         <iframe
  //           className='quicksight-iframe'
  //           // style={{ border: `1px solid ${PrimaryTheme.primaryGreyLight}` }}
  //           //   width={"1000px"}
  //           //   height={"1000px"}
  //           //  ref={dashboardRef}
  //           src={dashboardUrl}
  //         />
  //       {/* ))
  //     } */}
  //   </div>
  // );
  return (
    <>
      <br />
      <h2>Analytics Overview- Metric comparison</h2>
      <br />
      <AnalyticsGraphMetrics />
      <br />
      <h2>Analytics Overview- Business unit comparison</h2>
      <br />
      <AnalyticsGraphBUs />
    </>
  );
}
