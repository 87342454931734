import {
    POST_METRICS_DATA,
    EDIT_METRICS_DATA, DELETE_METRICS_DATA,
    GET_ALL_METRICS_DATA,
    GET_METRICS_STATUS,
    POST_METRICS_STATUS,
    PATCH_METRICS_STATUS,
    GET_DERIVED_METRICS,
    GET_MY_DERIVED_METRICS,
    DELETE_MY_DERIVED_METRICS,
    POST_MY_DERIVED_METRICS,
    GET_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    POST_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    PATCH_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    DELETE_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    GET_OMITTED_METRICS,
    POST_OMITTED_METRICS,
    PATCH_OMITTED_METRICS,
    DELETE_OMITTED_METRICS,
    BULK_UPLOAD_MY_METRICS_DATA,
    GET_DERIVED_METRICS_DATA_BY_MONTH,
    GET_MY_METRIC_DATA_LABELS,
    POST_MY_METRIC_DATA_LABELS,
    DELETE_MY_METRIC_DATA_LABELS,
    GET_CONTRIBUTORS_BY_METRICS,
    POST_CONTRIBUTORS_BY_METRICS,
    DELETE_CONTRIBUTORS_BY_METRICS,
    GET_MY_METRIC_DATA_BY_MONTH,
    GET_MY_METRIC_DATA_BULK_UPLOAD_FILES,
    GET_MY_METRIC_BULK_UPLOAD_DATA_BY_FILE_ID,
    PATCH_MY_METRIC_DATA_BULK_UPLOAD_FILES
} from "../../../Utils/Routes/DataEntryRoutes";
import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { AppDispatch, RootState } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import {
    setMetricStatusDM,
    setAssignedDepartmentByCategory,
    setOmittedMetrics,
    setLabels,
    setContributorsByMetrics,
    setMyMetricDataBulkUploadFiles,
    setCurrentMyMetricDataBulkUploadFileId,
} from "../../../Redux/DataManagerReducer";
import { BusinessUnitRefType } from "../../../Redux/Types/entityTypes";
import { getLogs } from "../../Settings/Actions";
import { metricDataType, metricType } from "../../../Redux/Types/dataManagerTypes";
import dayjs from "dayjs";
import { setDerivedMetrics, setMyDerivedMetrics, setMyDerivedMetricsDataByMonthAndBusinessUnit, setMyMetricsData, setMyMetricsDataByMonthAndBusinessUnit } from "../../../Redux/OnboardingReducer";
import { getCombinedSelectedMetrics } from "../../../views/Actions/OnboardingStep4Actions";

export const getTopicStatusDM = (id: number, businessUnit?: number, month?: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    let topicsData = getState().dataManager.metrics.tableDataDM;
    let status: any = 0; //0=pending, 1=filled, 2=approved
    if (!topicsData?.hasOwnProperty(id) || topicsData[id!]?.length === 0) return status;
    for (let i = 0; i < topicsData[id].length; i++) {
        let monthOfEntry = dayjs(topicsData[id][i].date_of_entry).month() + 1;
        if (topicsData[id][i]?.status === 2 && (!businessUnit || topicsData[id][i].business_unit === businessUnit) && (!month || monthOfEntry === month)) {
            if (status === 0) {
                status = 2;
            }
        }
        if (topicsData[id][i]?.status === 1 && (!businessUnit || topicsData[id][i].business_unit === businessUnit) && (!month || monthOfEntry === month)) {
            status = 1;
        }
    }
    return status;
}

export const getTotalMetricValueDM = (metric: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const tableDataDM = getState().dataManager.metrics.tableDataDM;
    const currentBusinessUnit = getState().common.homePage.currentBusinessUnit;
    const currentBusinessGroup = getState().common.homePage.currentBusinessGroup;
    const businessUnitRefs = getState().entity.BusinessUnitRefs;
    let totalValue: any = 0;
    if (!tableDataDM?.hasOwnProperty(metric.id)) return totalValue;
    // totalValue = tableDataDM[metric.id]?.reduce((a: any, b: any) => b?.status !== 3 ? (a + (b['value'] || 0)) : a, 0)
    if (metric.annual === false) {
        totalValue = tableDataDM[metric.id]?.reduce((a: any, b: any) => b?.status !== 3 ? (a + (b['value'] || 0)) : a, 0)
    }
    else {
        if (currentBusinessUnit) {
            totalValue = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item?.status !== 3)[0!]?.value || 0;
        } else if (currentBusinessGroup) {
            const uniqueBusinessUnits = businessUnitRefs?.data.filter((bu: BusinessUnitRefType) => bu.business_group === currentBusinessGroup).map((bu: BusinessUnitRefType) => bu.business_unit);
            uniqueBusinessUnits.forEach((bu: number) => {
                let value = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item?.status !== 3 && item.business_unit === bu)[0!]?.value || 0;
                totalValue += value;
            });

        } else {
            const uniqueBusinessUnits = Array.from(new Set(tableDataDM[metric.id].map((item: metricDataType) => item.business_unit)));
            uniqueBusinessUnits.forEach((bu: number) => {
                let value = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item?.status !== 3 && item.business_unit === bu)[0!]?.value || 0;
                totalValue += value;
            });
        }

    }
    return totalValue;
}

export const getApprovedMetricValueDM = (metric: metricType) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const tableDataDM = getState().dataManager.metrics.tableDataDM;
    const currentBusinessUnit = getState().common.homePage.currentBusinessUnit;
    const currentBusinessGroup = getState().common.homePage.currentBusinessGroup;
    const businessUnitRefs = getState().entity.BusinessUnitRefs;
    let totalValue: any = 0;
    if (!tableDataDM?.hasOwnProperty(metric.id)) return totalValue;
    // totalValue = tableDataDM[metric.id]?.reduce((a: any, b: any) => b?.status !== 3 ? (a + (b['value'] || 0)) : a, 0)
    if (metric.annual === false) {
        totalValue = tableDataDM[metric.id]?.reduce((a: any, b: any) => b?.status === 2 ? (a + (b['value'] || 0)) : a, 0)
    }
    else {
        if (currentBusinessUnit) {
            totalValue = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item?.status === 2)[0!]?.value || 0;
        } else if (currentBusinessGroup) {
            const uniqueBusinessUnits = businessUnitRefs?.data.filter((bu: BusinessUnitRefType) => bu.business_group === currentBusinessGroup).map((bu: BusinessUnitRefType) => bu.business_unit);
            uniqueBusinessUnits.forEach((bu: number) => {
                let value = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item?.status === 2 && item.business_unit === bu)[0!]?.value || 0;
                totalValue += value;
            });

        } else {
            const uniqueBusinessUnits = Array.from(new Set(tableDataDM[metric.id].map((item: metricDataType) => item.business_unit)));
            uniqueBusinessUnits.forEach((bu: number) => {
                let value = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item?.status === 2 && item.business_unit === bu)[0!]?.value || 0;
                totalValue += value;
            });
        }

    }
    return totalValue;
}

// changed
export const getMyDerivedMetrics = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    // new code
    dispatch(setMyDerivedMetrics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MY_DERIVED_METRICS());
    if (data) {
        const derivedMetrics = getState().onBoarding.metrics.derivedMetrics?.data;
        let dataArr = data?.data.map((item: any) => {
            const metricData = derivedMetrics?.find((metric) => metric.id === item.derived_metric);
            return {
                ...item,
                material_topic: metricData?.material_topic,
                pillars: metricData?.pillars,
                category: metricData?.category,
                group: metricData?.group,
                unit: metricData?.unit,
                applicable_to_all: metricData?.applicable_to_all,
                annual: metricData?.annual,
                tab: metricData?.tab,
                description: metricData?.description,
                bcode: metricData?.bcode,
                title: metricData?.title,
                condition: metricData?.condition,
                formula: metricData?.formula,
                direct: metricData?.direct,
            }
        });

        // new code
        dispatch(setMyDerivedMetrics({
            status: "success",
            data: dataArr
        }));
        return dataArr;
    }
    else {
        // new code
        dispatch(setMyDerivedMetrics({
            status: "error",
            data: []
        }));
        return null;
    }
}

export const postMyDerivedMetrics = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_MY_DERIVED_METRICS(data));
    if (dataRes) {
        await dispatch(getCombinedSelectedMetrics());
        return true;
    }
    else {
        return null
    }
}

export const deleteMyDerivedMetrics = (id: any, setLoading?: Function) => async (dispatch: AppDispatch) => {
    setLoading && setLoading(true);
    const [dataRes, error] = await handleAPICall(DELETE_MY_DERIVED_METRICS(id));
    if (dataRes) {
        BEMessage.success("Metric Deleted Successfully");
        dispatch(getCombinedSelectedMetrics());  
    }
    setLoading && setLoading(false);
}


// changed
export const getDerivedMetrics = () => async (dispatch: AppDispatch) => {
    dispatch(setDerivedMetrics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_DERIVED_METRICS());
    if (data) {
        let filteredDerivedMetrics: any = [];
        data?.data.forEach((d: any) => {
            if (d.pillar !== "0" && d.pillar !== null && d.pillar !== "" && d.pillar !== "nan" && d.pillar !== "undefined") {
                filteredDerivedMetrics.push(d);
            }
        });
        // new code
        dispatch(setDerivedMetrics({
            status: "success",
            data: filteredDerivedMetrics
        }));
        return filteredDerivedMetrics
    }
    else {
        // new code
        dispatch(setDerivedMetrics({
            status: "error",
            data: []
        }));
        return null;
    }
}


export const getMetricsStatus = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_METRICS_STATUS());
    if (data) {
        dispatch(setMetricStatusDM({
            status: "success",
            data: data?.data
        }));
    }
    else {
        dispatch(setMetricStatusDM({
            status: "error",
            data: []
        }));
    }
}

export const postMetricsStatus = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_METRICS_STATUS(data));
    if (dataRes) {
        BEMessage.success("Data Saved Successfully");
        dispatch(getMetricsStatus());
    }
}

export const patchMetricsStatus = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_METRICS_STATUS(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        dispatch(getMetricsStatus());
    }
}

// changed
export const getMyMetricsData = () => async (dispatch: AppDispatch) => {
    // new code
    dispatch(setMyMetricsData({
        status: "loading",
        data: []
    }));

    const [data, error] = await handleAPICall(GET_ALL_METRICS_DATA());
    if (data) {
        let dataToSet = data?.data.sort((a: any, b: any) => (a.timestamp > b.timestamp) ? 1 : -1);

        // new code
        dispatch(setMyMetricsData({
            status: "success",
            data: dataToSet
        }));
    }
    else {
        // new code
        dispatch(setMyMetricsData({
            status: "error",
            data: []
        }));
    }
}

export const postMetricsData = (data: any) => async (dispatch: AppDispatch, setState: () => RootState) => {
    const [dataRes, error] = await handleAPICall(POST_METRICS_DATA(data));
    if (dataRes) {
        BEMessage.success("Data Saved Successfully");
        let state = setState();
        let currentBU = state.common.homePage.currentBusinessUnit;
        dispatch(getMyMetricsData());
        dispatch(getLogs());
        return dataRes;
    }
    else {
        BEMessage.error("Something went wrong, Please try again");
        return null;
    }
}

export const editMetricsData = (id: any, data: any) => async (dispatch: AppDispatch, setState: () => RootState) => {
    const [dataRes, error] = await handleAPICall(EDIT_METRICS_DATA(id, data));
    if (dataRes) {
        let state = setState();
        let currentBU = state.common.homePage.currentBusinessUnit;
        dispatch(getMyMetricsData());
        return dataRes;
    }
    else {
        return null;
    }
}

export const deleteMetricsData = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_METRICS_DATA(id));
    if (dataRes) {
        BEMessage.success("Data Deleted Successfully");
        dispatch(getMyMetricsData());
    }
}

// changed
export const getDerivedMetricDataByMonth = () => async (dispatch: AppDispatch) => {
    // new code
    dispatch(setMyDerivedMetricsDataByMonthAndBusinessUnit({
        status: "loading",
        data: []
    }));


    const [data, error] = await handleAPICall(GET_DERIVED_METRICS_DATA_BY_MONTH());
    if (data) {
        // new code
        dispatch(setMyDerivedMetricsDataByMonthAndBusinessUnit({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        // new code
        dispatch(setMyDerivedMetricsDataByMonthAndBusinessUnit({
            status: "error",
            data: []
        }));
    }
}

export const getAssignedDepartmentByCategory = () => async (dispatch: AppDispatch) => {
    dispatch(setAssignedDepartmentByCategory({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_ASSIGNED_DEPARTMENT_BY_CATEGORY());
    if (data) {
        dispatch(setAssignedDepartmentByCategory({
            status: "success",
            data: data?.data
        }));
    }
    else {
        dispatch(setAssignedDepartmentByCategory({
            status: "error",
            data: []
        }));
    }
}

export const postAssignedDepartmentByCategory = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_ASSIGNED_DEPARTMENT_BY_CATEGORY(data));
    if (dataRes) {
        BEMessage.success("Department assigned successfully.");
        dispatch(getAssignedDepartmentByCategory());
    }
}

export const patchAssignedDepartmentByCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_ASSIGNED_DEPARTMENT_BY_CATEGORY(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        dispatch(getAssignedDepartmentByCategory());
    }
}

export const deleteAssignedDepartmentByCategory = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_ASSIGNED_DEPARTMENT_BY_CATEGORY(id));
    if (dataRes) {
        BEMessage.success("Department removed successfully.");
        dispatch(getAssignedDepartmentByCategory());
        dispatch(getContributorsByMetrics());
    }
}

export const getOmittedMetrics = () => async (dispatch: AppDispatch) => {
    dispatch(setOmittedMetrics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_OMITTED_METRICS());
    if (data) {
        dispatch(setOmittedMetrics({
            status: "success",
            data: data?.data
        }));
    }
    else {
        dispatch(setOmittedMetrics({
            status: "error",
            data: []
        }));
    }
}

export const postOmittedMetrics = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_OMITTED_METRICS(data));
    if (dataRes) {
        BEMessage.success("Metric omitted successfully.");
        dispatch(getOmittedMetrics());
    }
}

export const patchOmittedMetrics = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_OMITTED_METRICS(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        dispatch(getOmittedMetrics());
    }
}

export const deleteOmittedMetrics = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_OMITTED_METRICS(id));
    if (dataRes) {
        BEMessage.success("Metric unomitted successfully.");
        dispatch(getOmittedMetrics());
    }
}

export const getDataLabels = () => async (dispatch: AppDispatch) => {
    dispatch(setLabels({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MY_METRIC_DATA_LABELS());
    if (data) {
        dispatch(setLabels({
            status: "success",
            data: data?.data
        }));
    }
    else {
        dispatch(setLabels({
            status: "error",
            data: []
        }));
    }
}

export const postDataLabels = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_MY_METRIC_DATA_LABELS(data));
    if (dataRes) {
        dispatch(getDataLabels());
    }
}

export const deleteDataLabels = (label: string) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_MY_METRIC_DATA_LABELS(label));
    if (dataRes) {
        BEMessage.success("Label Deleted Successfully");
        dispatch(getDataLabels());
    }
    else {
        BEMessage.error("Something went wrong, Please try again");
    }
}

export const getContributorsByMetrics = () => async (dispatch: AppDispatch) => {
    dispatch(setContributorsByMetrics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_CONTRIBUTORS_BY_METRICS());
    if (data) {
        dispatch(setContributorsByMetrics({
            status: "success",
            data: data?.data
        }));
        return data?.data;
    }
    else {
        dispatch(setContributorsByMetrics({
            status: "error",
            data: []
        }));
        return null;
    }
}

export const postContributorsByMetrics = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_CONTRIBUTORS_BY_METRICS(data));
    if (dataRes) {
        BEMessage.success("Contributor added successfully.");
        dispatch(getContributorsByMetrics());
        return dataRes?.data;
    }
    else {
        BEMessage.error("Something went wrong, Please try again");
        return null;
    }
}

export const deleteContributorsByMetrics = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_CONTRIBUTORS_BY_METRICS(id));
    if (dataRes) {
        BEMessage.success("Contributor removed successfully.");
        dispatch(getContributorsByMetrics());
    }
}


// changed
export const getMyMetricsDataByMonth = () => async (dispatch: AppDispatch) => {
    // new code
    dispatch(setMyMetricsDataByMonthAndBusinessUnit({
        status: "loading",
        data: []
    }));

    const [data, error] = await handleAPICall(GET_MY_METRIC_DATA_BY_MONTH());
    if (data) {
        // new code
        dispatch(setMyMetricsDataByMonthAndBusinessUnit({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        // new code
        dispatch(setMyMetricsDataByMonthAndBusinessUnit({
            status: "error",
            data: []
        }));

    }
}

export const getMyMetricsDataBulkUploadFiles = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_MY_METRIC_DATA_BULK_UPLOAD_FILES());
    if (data) {
        dispatch(setMyMetricDataBulkUploadFiles({
            status: "success",
            data: data?.data
        }));
    }
    else {
        dispatch(setMyMetricDataBulkUploadFiles({
            status: "error",
            data: []
        }));
    }
}

export const patchMyMetricDataBulkUploadFiles = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_MY_METRIC_DATA_BULK_UPLOAD_FILES(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        getMyMetricsDataBulkUploadFiles();
    }
    else {
        BEMessage.error("Something went wrong, Please try again");
    }
}

export const bulk_upload_my_metric_data_api = (data: any, file_id?: number) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(BULK_UPLOAD_MY_METRICS_DATA(data, file_id));
    if (dataRes) {
        let task_id = null;
        if (file_id === undefined) {
            task_id = dataRes?.data?.task_id;
            return task_id;
        }
        else {
            BEMessage.success(dataRes?.msg);
            dispatch(getMyMetricsDataBulkUploadFiles());
            dispatch(getMyMetricsData());
            return dataRes;
        }
    }
    else {
        console.log(error);
        BEMessage.error(error?.error || "Something went wrong, Please try again");
        return null;
    }
}

export const getMyMetricBulkUploadData = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentFileId = getState().dataManager.currentMyMetricDataBulkUploadFileId;
    if (!currentFileId) return;
    const [data, error] = await handleAPICall(GET_MY_METRIC_BULK_UPLOAD_DATA_BY_FILE_ID(currentFileId));
    if (data) {
        return data?.data;
    }
    else {
        console.log(error);
        return null;
    }
}


export const myMetricDataBulkUpload = (my_metric_id: number, status: number, file_name?: string | null, file?: any, file_id?: number, rejection_reason?: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setCurrentMyMetricDataBulkUploadFileId(null));
    const currentBusinessUnit = getState().common.homePage.currentBusinessUnit;
    let dataToSend: any = {
        my_metric: my_metric_id,
        status: status,
        business_unit: currentBusinessUnit,
        rejection_reason: rejection_reason,
        MY_METRIC_DATA_FILE: file,
        file_name: file_name
    }
    if (!currentBusinessUnit) {
        dataToSend = {
            my_metric: my_metric_id,
            status: status,
            rejection_reason: rejection_reason,
            MY_METRIC_DATA_FILE: file,
            file_name: file_name
        }
    }
    const resp = await dispatch(bulk_upload_my_metric_data_api(dataToSend, file_id));
    if (status === 0) {
        return resp;
    }
}



// old code

// export const getMyDerivedMetricsData = (currentYear:number) => async (dispatch:AppDispatch,getState:()=>RootState) => {
//     dispatch(setMyDerivedMetricsDataDM({
//             status: "loading",
//             data: []
//         }));
//     const yearEnd = getState().companies.companyDetails?.data?.reporting_year_end;
//     const dates = getStartDateAndEndDate(currentYear,yearEnd || "12-31");
//     if(dates === null) return;
//     const [data, error] = await handleAPICall(GET_DERIVED_METRICS_DATA(dates?.startDate ,dates?.endDate));
//     if (data) {
//         dispatch(setMyDerivedMetricsDataDM({
//             status: "success",
//             data: data?.data
//         }));
//     }
//     else{
//         console.log(error);
//         dispatch(setMyDerivedMetricsDataDM({
//             status: "error",
//             data: []
//         }));
//     }
// }


// export const getDerivedMetricDataById = (currentYear: number, id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
//     const yearEnd = getState().companies.companyDetails?.data?.reporting_year_end;
//     const dates = getStartDateAndEndDate(currentYear, yearEnd || "12-31");
//     if (dates === null) return;
//     const [data, error] = await handleAPICall(GET_DERIVED_METRICS_DATA(dates?.startDate, dates?.endDate, id));
//     if (data) {
//         return data?.data
//     }
//     else {
//         return null;
//     }
// }