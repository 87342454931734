import { ColumnsType } from 'antd/es/table';
import * as React from 'react';
import EditIcon from '../../../../../Components/BEIcons/EditIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { NewBETable } from '../../../../../Components/BETable/newBETable';
import { AddAsset } from '../AddAsset';
import { EditReport } from '../NewEditReport';
import { calculateAssetProgress, getGresbAuthorizationCode, getGRESBSlugData, isGresbAuthorized, postGresbPortalAssetData } from '../../../Actions/gresbActions';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { setCurrentGRESBSection, setSelectedGresbAsset } from '../../../../../Redux/ReportingReducer';
import { useNavigate } from 'react-router-dom';
import { BEMessage } from '../../../../../Components/BEMessage';
import { patchReportTemplate } from '../../../Actions/template';

export interface IChooseAssetsProps {
    open: boolean;
    setOpen: Function;
}

export function
    ChooseAssets(props: IChooseAssetsProps) {

    const currentEntity = useTypedSelector(state => state.reporting.currentEntity);
    const [AddAssetOpen, setAddAssetOpen] = React.useState(false);
    const [editReportOpen, setEditReportOpen] = React.useState(false);
    const gresbSlugData = useTypedSelector(state => state.reporting.gresbSlugData);
    const [data, setData] = React.useState<any>([]);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const [loadingMarkForReview, setLoadingMarkForReview] = React.useState(false);
    const gresbAssets = useTypedSelector(state => state.reporting.gresbAssetData);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const role = localStorage.getItem('role');

    React.useEffect(() => {
        if (gresbSlugData?.status === 'idle')
            dispatch(getGRESBSlugData());
    }, []);

    const columns: ColumnsType<any> = [
        {
            title: 'Asset name',
            dataIndex: 'name',
            render: (value, record) => {
                return <div
                    onClick={() => {
                        props.setOpen(false);
                        dispatch(setSelectedGresbAsset(record));
                        setEditReportOpen(true)
                    }}
                    style={{ cursor: 'pointer', display: "flex", flexDirection: "row", gap: "1rem" }}>
                    <p
                        style={{
                            textDecoration: 'underline',
                        }}>
                        {value}
                    </p>
                    <div style={{ width: "1rem" }}>
                        <EditIcon inheritSize stroke={PrimaryTheme.primaryBlack} />
                    </div>
                </div>
            }
        },
        {
            title: 'Property type',
            dataIndex: 'property_type'
        },
        {
            title: 'Country',
            dataIndex: 'country'
        },
        {
            title: 'City',
            dataIndex: 'city'
        },
        {
            title: 'State',
            dataIndex: 'state'
        },
        {
            title: 'progress',
            dataIndex: 'progress',
            render: (value, record) => {
                return <p>{dispatch(calculateAssetProgress(record?.id || 0))}%</p>
            }
        },
        {
            title: 'Errors',
            dataIndex: 'errors',
            render: (value, record) => {
                return <p>{value === -1 ? "-" : value}</p>
            }
        },
        {
            title: 'Outliers',
            dataIndex: 'outliers',
            render: (value, record) => {
                return <p>{value === -1 ? "-" : value}</p>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (value, record) => {
                return <div
                    style={{ cursor: 'pointer', display: "flex", flexDirection: "row", gap: "1rem" }}>
                    <BEButton
                        className='secondary-green'
                        size='large'
                        onClick={async () => {
                            if (dispatch(calculateAssetProgress(record?.id || 0)) < 75) {
                                BEMessage.info('Progress must be at least 75% to validate the asset');
                                return;
                            }
                            if (isGresbAuthorized()) {
                                await dispatch(postGresbPortalAssetData(Number(currentEntity?.gresb_entity_id), record?.id));
                            } else {
                                BEMessage.info('Please authorize GRESB first');
                            }
                        }}
                    >
                        Transfer data
                    </BEButton>
                    {/* <BEButton
                        className='primary'
                        size='large'
                        disabled={true}
                        onClick={() => {
                            console.log('record', record);
                        }}
                    >
                        Submit
                    </BEButton> */}
                </div>
            }
        }
    ];

    const handleMarkForReview = async () => {
        setLoadingMarkForReview(true);
        await dispatch(patchReportTemplate(currentReport?.id || 0, { stage: 4 }, true))
        BEMessage.success('Report marked for review successfully.')
        setLoadingMarkForReview(false)
    }

    React.useEffect(() => {
        if (gresbAssets?.status === 'success') {
            setData(gresbAssets?.data.filter((item: any) => item.report === currentReport?.id));
        }
    }, [gresbAssets, currentReport]);

    return (
        <BEDrawer
            width='100%'
            footerHeight={64}
            heading='Assets in the portfolio'
            open={props.open}
            setOpen={props.setOpen}
            footer={
                <div style={{ display: "flex", gap: "1rem" }}>
                    <BEButton
                        disabled={(currentReport?.progress && currentReport.progress < 100) || (role !== 'SUPER_ADMIN' && role !== 'ADMIN')}
                        loading={loadingMarkForReview}
                        onClick={handleMarkForReview}
                    >
                        Mark for Review
                    </BEButton>
                    <BEButton
                        className='primary'
                        onClick={() => {
                            props.setOpen(false);
                            dispatch(setCurrentGRESBSection('entity'));
                            setEditReportOpen(true);
                        }}
                    >
                        Switch to entity
                    </BEButton>
                </div>
            }
            theme='dark'
            closeIconFunction={() => {
                navigate('/home/reporting/reports');
            }}
        >
            <NewBETable
                search
                data={data}
                columns={columns}
                pagination
                TableTopRightComponents={
                    <div
                        style={{ cursor: 'pointer', display: "flex", flexDirection: "row", gap: "1rem" }}>
                        <BEButton
                            className='primary'
                            size='large'
                            onClick={() => {
                                setAddAssetOpen(true);
                            }}
                        >
                            Add Asset
                        </BEButton>
                        {/* <BEButton
                            size='large'
                            onClick={async () => {
                                await dispatch(getGresbAuthorizationCode(1, currentReport?.id || 0));
                            }}
                        >
                            Authorize with GRESB
                        </BEButton> */}
                        {/* <BEButton
                            className='primary'
                            size='large'
                            onClick={() => {
                                console.log('Add asset');
                            }}
                        >
                            Import business units
                        </BEButton> */}
                    </div>
                }
            />
            <AddAsset open={AddAssetOpen} setOpen={setAddAssetOpen} />
            <EditReport open={editReportOpen} setOpen={setEditReportOpen} />
        </BEDrawer>
    );
}
