import { Col, Modal, Row, Upload, UploadProps, Image, GetProp, UploadFile } from 'antd';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import React from 'react';
import { getUserProfile, patchUserProfile } from '../../../Actions/UsersManagementActions';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import Avatar from '../../../../assets/images/Avatar.png';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export interface IProfileModalProps {
    open: boolean;
    setOpen: Function;
}

export function ProfileModal(props: IProfileModalProps) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
    const userName = userInfo?.first_name ? `${userInfo?.first_name} ${userInfo?.last_name}` : `${userInfo?.username}`;
    const userRole = userInfo?.role;
    const dispatch = useAppDispatch();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const userProfile = useTypedSelector((state) => state.user.userProfile);
    const [load, setLoad] = React.useState(false);

    React.useEffect(() => {
        if (userProfile?.status === 'idle')
            dispatch(getUserProfile());
    }, []);

    React.useEffect(() => {
        const data: UploadFile = {
            uid: '1',
            name: 'user.png',
            status: 'done',
            url: userProfile?.data?.profile_pic,
        }
        setFileList([data]);
    }, [userProfile]);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }
        console.log(file);
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    return (
        <Modal
            title="My Profile"
            closable={true}
            open={props.open}
            footer={null}
            onCancel={() => props.setOpen(false)}
        >
            <Row style={{ padding: "1rem 0" }} gutter={[10, 10]}>
                <Col span={6}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "1.5rem" }}>
                        <Upload
                            listType="picture-circle"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList.length == 1 ? null : uploadButton}
                        </Upload>
                        {previewImage && (
                            <Image
                                style={{ width: "100px" }}
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </div>
                </Col>
                <Col offset={1} span={17}>
                    <Row style={{ margin: "1rem 0" }}>
                        <p style={{ fontSize: "0.8rem", color: PrimaryTheme.primaryGray }}>Name</p>
                        <BEInput name='name' style={{ fontWeight: "600", color: "black", opacity: "1" }} value={userName} disabled />
                    </Row>
                    <Row style={{ margin: "1rem 0" }}>
                        <p style={{ fontSize: "0.8rem", color: PrimaryTheme.primaryGray }}>Role</p>
                        <BEInput name='role' style={{ fontWeight: "600", color: "black", opacity: "1" }} color={PrimaryTheme.primaryBlack} value={userRole} disabled />
                    </Row>
                </Col>
            </Row>
            <Row justify={"end"}>
                <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                    <BEButton
                        onClick={() => props.setOpen(false)}
                        size='large'>
                        Close
                    </BEButton>
                    <BEButton
                        size='large'
                        className='primary'
                        loading={load}
                        onClick={async () => {
                            let data = {};
                            let isFile = false;
                            if (fileList.length === 0) {
                                data = {
                                    profile_pic: null
                                }
                            } else {
                                const fileItem = fileList[0].originFileObj as FileType
                                data = {
                                    profile_pic: fileItem
                                }
                                isFile = true;
                            }
                            setLoad(true);
                            await dispatch(patchUserProfile(data, isFile));
                            setLoad(false);
                            props.setOpen(false);
                        }}
                    >
                        Save
                    </BEButton>
                </div>
            </Row>

        </Modal>
    );
}
