import * as React from 'react';
import { Table } from 'antd';
import { ColumnsType, ColumnType, TableProps } from "antd/es/table";
import ArrowDownIcon from '../BEIcons/ArrowDownIcon';
import FilterIcon from '../BEIcons/FilterIcon';
import { PrimaryTheme } from '../../Config/Theme/theames';
import { TableRowSelection } from 'antd/es/table/interface';
import { TableActions } from './TableActions';
import './style.scss'
import { useAppDispatch } from '../../Config/Hooks/useAppDispatch';
import { setSelectedRow } from '../../Redux/CommonReducer';

//***//filter dropdown to be made

export interface IBETableProps {
  data: Array<any>,
  columns: ColumnsType<any>;
  loading?: boolean,
  pagination?: boolean
  tableProps?: TableProps<any>
  handleRowSelection?: Function
  handleDelete?: Function
  handleEdit?: Function
  rowKey?: string
  className?: string
  disabledRows?: Array<any>
  defaultSelectedRows?: Array<React.Key>
  rowClassName?: any
  formatTitle?: boolean
  handleChangePage?:Function
}

export function BETable(props: IBETableProps) {
  const [coloumns, setColoumns] = React.useState<ColumnsType<any>>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [oldData, setOldData] = React.useState<any>([]);
  const dispatch = useAppDispatch();
  //   *******Row selection*********/
  const SetCurrentRow = (record: any) => {
    dispatch(setSelectedRow(record));
  }
  //   *******Row selection*********/

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    props.handleRowSelection && props.handleRowSelection(newSelectedRowKeys.filter((key) => !props.disabledRows?.find((row) => row.id === key)));
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    getCheckboxProps: (record) => ({
      disabled: props.disabledRows?props.disabledRows?.findIndex((row) => row.id === record.id) !== -1:false, 
    }),
    fixed: true,
    onChange: onSelectChange,
  };

  React.useEffect(() => {
    if(oldData.length !== props?.data?.length) {
    props.handleChangePage && setPage(1)
    props.handleChangePage && props.handleChangePage(1)
    setOldData(props?.data)
    }
  }, [props?.data])

  React.useEffect(() => {
    if (props.defaultSelectedRows?.length) {
      setSelectedRowKeys([...props.defaultSelectedRows,...selectedRowKeys]);
    }
    else if (props.defaultSelectedRows) {
      setSelectedRowKeys([]);
      props.handleRowSelection!([])
    }
  }, [])

  React.useEffect(() => {
    let tempColumns: ColumnsType = [];
    props.columns.map((column) => {
      tempColumns.push({
        ...column,
        title: !column.title ? '' : (typeof column.title=== "string" && !props.formatTitle) ? String(column.title)?.toUpperCase() : column.title,
        sortIcon: column.sorter ? ({ sortOrder: SortOrder }) => !SortOrder ?
          <div style={{ height: '16px' }}><ArrowDownIcon inheritSize /></div> : SortOrder === 'ascend' ?
            <div style={{ rotate: '180deg', margin: 0, padding: 0, height: '16px' }}><ArrowDownIcon inheritSize fill={PrimaryTheme.primaryGreen} /></div> :
            <div style={{ height: '16px' }}><ArrowDownIcon inheritSize fill={PrimaryTheme.primaryGreen} /></div> : undefined,
        filterIcon: column.filters ? <div style={{ height: '16px' }}><FilterIcon inheritSize /></div> : undefined,
        showSorterTooltip: false
      });
    })
    if (props.handleDelete || props.handleEdit)
      tempColumns.push({
        title: 'Actions', render: (record) =>
          <div onMouseEnter={() => { SetCurrentRow(record) }}>
            <TableActions
              handleDelete={props.handleDelete}
              handleEdit={props.handleEdit} 
            />
          </div>
      })
    setColoumns(tempColumns);
  }, [props.columns]);

  return (
    <div className='be-table'>
      <Table
        {...props.tableProps}
        className={props.className}
        rowKey={props?.rowKey}
        rowSelection={props.handleRowSelection ? rowSelection : undefined}
        columns={coloumns}
        dataSource={props?.data}
        loading={props?.loading}
        pagination={props.pagination ? 
          { 
            position: ['bottomCenter'] ,
            current:page,
            onChange:(page)=>{
              setPage(page)
              props.handleChangePage && props.handleChangePage(page)
            },
            showSizeChanger:false,
          }
           : false}
        rowClassName={props.rowClassName}
      />
    </div>
  );
}
