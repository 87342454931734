import React, { useState } from 'react'
import { BETable } from '../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import EditIcon from '../../../../../Components/BEIcons/EditIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import EditConversionFactors from '../EditConversionFactors';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { setCurrentScope1Configuration } from '../../../../../Redux/EmmisionsReducer';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import ZoomInIcon from '../../../../../Components/BEIcons/ZoomInIcon';

const Scope2Table = () => {
  const [open, setOpen] = useState(false)
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const scope2Configurations = useTypedSelector(state => state.emissions.configurations.scope2Configurations)
  const role = localStorage.getItem('role');
  const dispatch = useAppDispatch();


  React.useEffect(() => {
    console.log(scope2Configurations?.data);
  }, [scope2Configurations])





  const columns: ColumnsType<any> = [
    {
      title: "Sr. No.",
      dataIndex: "",
      render: (value, record, ind) => {
        return ind + 1;
      },
      width: "100px",
    },
    {
      title: "Metric Name",
      dataIndex: "source",
      render: (value) => {
        return "Purchased grid electricity";
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      render: (value) => {
        return "KWh";
      },
    },
    {
      title: "Business Unit",
      dataIndex: "business_unit",
      filters: Array.from(new Set(scope2Configurations?.data.map((item) => item.business_unit))).map((item) => ({text: item, value: item})),
      onFilter: (value, record) => record.business_unit === value,
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: 'Source',
      dataIndex: 'source',
      onFilter: (value, record) => record.source === value,
      filters: [
        {
          text: 'Custom',
          value: 1,
        },
        {
          text: 'Custom (Direct Conversion)',
          value: 2,
        },
        {
          text: 'DEFRA (Default)',
          value: 3,
        },
        {
          text: 'IPCC (Default)',
          value: 4,
        },
      ],
      render: (value, record) => {
        return `${value} - ${record?.specification} - ${record?.ef_year}`;
      }
    },
    {
      title: "Conversion Factors",
      dataIndex: "metric",
      width: "200px",
      align: "left",
      render: (value, record) => {
        return (
          <div
            style={{ cursor: "pointer", width: "1rem" }}
            onMouseEnter={() => {
              dispatch(setCurrentScope1Configuration(record));
            }}
            onClick={() => setOpen(true)}
          >
            {
              role !== 'SUPER_ADMIN' && role !== 'ESG_CONSULTANT' ?
                <ZoomInIcon inheritSize fill={PrimaryTheme.primaryGray} /> :
                <EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
            }
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ margin: "1rem 0" }}>
      <EditConversionFactors open={open} setOpen={setOpen} />
      <BETable
        loading={scope2Configurations?.status === 'loading'}
        data={scope2Configurations?.data.filter((item) => item?.year === currentYear)}
        columns={columns}
        pagination={true}
      />
    </div>
  );
}

export default Scope2Table